import { ReactNode } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  ListItemButtonProps,
} from '@mui/material';
// config
import { ICON, NAVBAR } from '../../../config';

// ----------------------------------------------------------------------

type IProps = LinkProps & ListItemButtonProps;

export interface ListItemStyleProps extends IProps {
  component?: ReactNode;
  to?: string;
  activeRoot?: boolean;
  activeSub?: boolean;
  subItem?: boolean;
  roles?: string[];
  hasChildren?: boolean;
  isChild?: boolean;
}

const skipForwardProps = ['activeRoot', 'activeSub', 'subItem', 'hasChildren', 'isChild'];

export const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) => !(typeof prop === 'string' && skipForwardProps.indexOf(prop) > -1),
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme, hasChildren, isChild }) => ({
  ...theme.typography.body2,
  position: 'relative',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,

  // isChild
  ...(isChild && {
    // marginBottom: theme.spacing(0.5),
  }),

  // activeRoot without children
  ...(activeRoot && !hasChildren && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, .85),
    },
    // '&:hover': {
    //   color: theme.palette.text.primary,
    // },
  }),
  // activeRoot with children
  ...(activeRoot && hasChildren && {
    ...theme.typography.subtitle2,
    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    // marginBottom: theme.spacing(0.5),
    // color: theme.palette.primary.main,
    // backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    // color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
    // '&:hover': {
    //   color: theme.palette.text.primary,
    // },
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

interface ListItemTextStyleProps extends ListItemButtonProps {
  isCollapse?: boolean;
}

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  whiteSpace: 'nowrap',
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
});
