import { AxiosRequestHeaders } from 'axios';
import axios from 'axios-observable';
// config
import { API_PREFIX } from 'src/constants/api';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: API_PREFIX,
});

interface MyAxiosHeaders {
  Authorization: string | null;
  language: string;
}

const headers: MyAxiosHeaders = {
  Authorization: null,
  language: '',
}

axiosInstance.interceptors.request.use((request) => {
  const isRemoteUrl = request.url && request.url.startsWith('http') && !request.url.startsWith(request.baseURL!);

  if (!isRemoteUrl) {
    let myHeaders: AxiosRequestHeaders = {};
    myHeaders.language = headers.language;
    if (headers.Authorization) {
      myHeaders.Authorization = headers.Authorization;
    }
    request.headers = {
      ...myHeaders,
      ...request.headers,
    };
  }

  return request;
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const setHeader = <T extends keyof MyAxiosHeaders>(header: T, value: MyAxiosHeaders[T]) => {
  headers[header] = value;
}

export default axiosInstance;
