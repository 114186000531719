import { Dialog, DialogProps, SxProps, Theme } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import { FadeIn, SlideUp } from './DialogTransition';
import _ from 'lodash';

export interface CustomDialogProps extends DialogProps {

}
export default function CustomDialog({
  sx,
  ...others
}: CustomDialogProps) {
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  let mySx: SxProps<Theme> = {};

  if (isMobile) {
    mySx = {
      '& .MuiDialog-paper': {
        bottom: `0`,
        position: `absolute`,
        borderRadius: `12px 12px 0 0 !important`,
        m: 0
      },
    };
  }
  if (sx) {
    mySx = _.merge({}, mySx, sx);
  }

  return <Dialog
    maxWidth={isDesktop ? 'md' : 'sm'}
    sx={mySx}
    TransitionComponent={isMobile ? SlideUp : FadeIn}
    {...others}
  />
}