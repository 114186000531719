import { Box, Button, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import QRCode from "react-qr-code";
import useAuth from "src/hooks/useAuth";
import CopyClipboard from "src/components/CopyClipboard";
import CustomDialog from 'src/components/CustomDialog';

export default function ReferralPopover() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { user } = useAuth();
  const { translate } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const closeIconColor = isLight ? theme.palette.grey[500] : theme.palette.grey[0];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  if (!user) {
    return (<></>);
  }
  return (
    <>
      <Button variant='text'
        sx={{
          textTransform: 'unset',
          color: 'text.primary',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'unset',
          },
          pl: 0,
          pr: 1
        }}
        fullWidth
        endIcon={
          <Iconify icon={'eva:chevron-right-outline'} />
        }
        disableRipple
        onClick={handleOpen}
      >
        <Typography sx={{ color: 'text.primary' }}>{translate('header.setting_list.my_referral_list')}</Typography>
      </Button>

      <CustomDialog
        onClose={handleClose}
        open={Boolean(open)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: theme.typography.fontWeightMedium
          }}
        >
          {translate('header.setting_list.my_referral_list')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <Iconify icon={'eva:close-fill'} color={closeIconColor} width={32} height={32} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction='column' spacing={2} alignItems="center">
            <Box sx={{ background: theme.palette.grey[0], padding: '16px', width: 'fit-content', borderRadius: '8px' }}>
              <QRCode value={user.referral_link} />
            </Box>
            <Typography textAlign="center" sx={{ wordBreak: 'break-all' }}>{user.referral_link}</Typography>
            <Stack direction="row" sx={{ width:'100%' }}>
              {/* <Button>share</Button> */}
              <CopyClipboard
                buttonText={translate('common.copy_link')}
                value={user.referral_link}
                copiedElement={translate('my_referral_list.referral_link')}
                buttonProps={{
                  color: 'text.primary',
                  textTransform: "unset",
                  borderColor: theme.palette.grey[50],
                  flex: '1',
                }}
              />
            </Stack>
          </Stack>
        </DialogContent>
      </CustomDialog>
    </>
  )
}