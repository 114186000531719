/* eslint-disable no-template-curly-in-string */
import printValue from './printValue';
import { ILangYup } from './yup.interface';

export interface NotTypeLocale {
  cast: string;
  mixed: string;
  not_mixed: string;
  full_stop: string;
  unknown_type: string;
}

// type Message = NonNullable<NonNullable<ILangYup['mixed']>['notType']>;

// type Params = Parameters<NonNullable<NonNullable<ILangYup['mixed']>['notType']>>

export default function notType(
  { path, type, value, originalValue }: any,
  lang: ILangYup,
  locale: NotTypeLocale,
): string {
  const castMsg =
    originalValue != null && originalValue !== value
      // ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
      ? `${locale.cast.replace('${value}', `\`${printValue(originalValue, true)}\``)}`
      : `${locale.full_stop}`;

  // const msgMixed = `${path} must match the configured type. ` +
  //   `The validated value was: \`${printValue(value, true)}\`` +
  //   castMsg;
  const msgMixed = `
    ${locale.mixed
      .replace('${path}', path)
      .replace('${value}', `\`${printValue(value, true)}\``)}` +
    castMsg;

  // const msgNotMixed = `${path} must be a \`${type}\` type, ` +
  //   `but the final value wasddd: \`${printValue(value, true)}\`` +
  //   castMsg;
  let typeMsg = locale.unknown_type;
  const type2 = type as keyof ILangYup['_additional']['_type'];
  if (lang._additional._type[type2]) {
    typeMsg = lang._additional._type[type2];
  }
  // const msgNotMixed = `${path} must be ${typeMsg}`;
  const msgNotMixed = `${locale.not_mixed.replace('${path}', path).replace('${value}', typeMsg)}`;

  return type !== 'mixed' ? msgNotMixed : msgMixed;
}