// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    index: path(ROOTS_DASHBOARD, '/index'),
  },
};

export const PATH_PAGE = {
  announcement: '/announcement',
  terms_conditions: '/terms-conditions',
  privacy_policy: '/privacy-policy',
  transaction_history: '/transaction-history',
};
