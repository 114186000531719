import { Button, Typography, DialogTitle, IconButton, DialogContent, useTheme, Stack, Grid, Divider, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import Image from "src/components/Image";
import useResponsive from "src/hooks/useResponsive";
import mock from 'src/utils/axiosMock';
import { API_LIST } from "src/constants";
import { catchError, finalize, Subscription, throwError } from "rxjs";
import axios from 'src/utils/axios';
import useAuth from "src/hooks/useAuth";
import FilterForm from "src/components/FilterForm";
import { RHFSearchTextField } from "src/components/hook-form";
import { useForm } from "react-hook-form";
import Scrollbar from "src/components/Scrollbar";
import LoadingOverlay from "src/components/LoadingOverlay";
import { fMiddleEllipsis } from "src/utils/formatString";
import CustomDialog from 'src/components/CustomDialog';

interface refereeList {
  referee_address: string;
  referee_gp_sales_code: string;
}

type SearchFormValuesProps = {
  s?: string;
};

export default function MyNetworkPopover() {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const isLight = theme.palette.mode === 'light';
  const { translate } = useLocales();
  const { user } = useAuth();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const closeIconColor = isLight ? theme.palette.grey[500] : theme.palette.grey[0];

  const [dataSubs, setDataSubs] = useState<Subscription>();
  const [loading, setLoading] = useState(false);
  const [refereesList, setrefereesList] = useState<refereeList[]>([]);
  const [searchParams, setSearchParams] = useState<SearchFormValuesProps>({});

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    methods.reset();
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

// mock ===============================================================================
  const mockData = {
    data: [
      {
        referee_address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
        referee_gp_sales_code: '19847286.12920300009',
      },
      {
        referee_address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
        referee_gp_sales_code: '19847286.12920300009',
      },
      {
        referee_address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
        referee_gp_sales_code: 'v19847286.12920300009',
      },
      {
        referee_address: 'testobR76b53LETtpyT',
        referee_gp_sales_code: '19847286.12920300009',
      },
      {
        referee_address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
        referee_gp_sales_code: '19847286.12920300009',
      },
      {
        referee_address: '1BoatSLRHtKNngkdXEeobR76b53LETtpyT',
        referee_gp_sales_code: 'v19847286.12920300009',
      },
    ]
  };

  const filterReferees = (config: any) => {
    let { params } = config;
    let currentData = {
      ...mockData
    };
    let selectedData: any[] = [];
    
    if (params.s) {
      currentData.data.forEach(element => {
        if (element.referee_address.toLowerCase().match(params.s.toLowerCase())) {
          selectedData.push(element);
        }
        if (selectedData) {
          currentData.data = selectedData;
        }
      });
      return currentData;
    }  else {
      return mockData;
    }
  };

  mock.onGet(API_LIST.GET_REFEREES)
    .reply((config) => [200, filterReferees(config) ]);

  const methods = useForm({});

  const getReferees = () => {
    if (dataSubs) {
      dataSubs.unsubscribe();
    }

    let url = API_LIST.GET_REFEREES;
    setLoading(true)
    const currentSub = axios.get(url, { params: searchParams })
      .pipe(catchError((err) => throwError(() => err)))
      .pipe(finalize(() => setLoading(false)))
      .subscribe((res) => {
        setrefereesList(res.data.data);
      });
    setDataSubs(currentSub);
  };



  useEffect(() => {
    getReferees();
  }, [searchParams]);

  if (!user) {
    return (<></>)
  }
  return (
    <>
      <Button
        variant='text'
        sx={{
          textTransform: 'unset',
          color: 'text.primary',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'unset',
          },
          pl: 0,
          pr: 1
        }}
        fullWidth
        endIcon={
          <Iconify icon={'eva:chevron-right-outline'} />
        }
        disableRipple
        onClick={handleOpen}
      >
        <Typography sx={{ color: 'text.primary' }}>{translate('header.setting_list.my_network')}</Typography>
      </Button>

      <CustomDialog
        onClose={handleClose}
        open={Boolean(open)}
        fullWidth
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {translate('header.setting_list.my_network')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <Iconify icon={'eva:close-fill'} color={closeIconColor} width={32} height={32} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LoadingOverlay isShow={loading} >
            <Grid
              container
              spacing={2}
              sx={{
                width: "100%",
                mx: 0,
                p: {
                  lg: theme.spacing(1)
                },
                '& .MuiGrid-item': {
                  pl: {
                    xs: theme.spacing(0),
                    md: theme.spacing(2)
                  }
                }
              }}
            >
              <Grid item xs={12} md={6}>
                <Stack direction="column" spacing={4}>
                  <Stack direction="column" spacing={2}>
                    <Image
                      src={isLight ? "/assets/illustrations/illustration_referral.svg" : "/assets/illustrations/illustration_referral_dark.png"}
                      sx={{
                        px: 5,
                        m: 'auto',
                        maxWidth: '350px',
                      }}
                    />
                    <Typography
                      variant="h5"
                      color="primary.main"
                      textAlign='center'
                      sx={{
                        fontWeight: theme.typography.fontWeightMedium
                      }}
                    >
                      {refereesList.length}{' '}{translate('my_network.referees')}
                    </Typography>
                    <Typography>{translate('my_network.refer_to_friend')}</Typography>
                  </Stack>
                  <Alert
                    className="alert-gray"
                    sx={{
                        marginRight: {
                          lg: theme.spacing(3) + '!important'
                        },
                      }}
                    icon={false}
                  >
                    <Stack direction="column" spacing={1}>
                      <Typography>{translate('my_network.your_gp_sales')}</Typography>
                      <Typography>{user.gp_sales}</Typography>
                    </Stack>
                  </Alert>
                </Stack>
              </Grid>
              {isDesktop && <Divider orientation="vertical" flexItem sx={{ marginRight: "-1px" }} />}
              <Grid item xs={12} md={6}>
                <Stack direction="column" spacing={2}>
                  <Typography>{translate('my_network.your_referees_gp_sales')}</Typography>
                  <FilterForm methods={methods} onFilter={setSearchParams}>
                    <RHFSearchTextField />
                  </FilterForm>
                    <Scrollbar sx={{
                      height: {
                        md: '385px'
                      }
                    }}>
                      <Stack direction="column" spacing={2}>
                      {
                        refereesList.map((element, index) => (
                          <Alert
                            className="alert-gray"
                            icon={false}
                            key={`gpSaleCard_` + index}
                          >
                            <Stack direction="column" spacing={1}>
                              <Typography>{fMiddleEllipsis(element.referee_address)}</Typography>
                              <Typography>{element.referee_gp_sales_code}</Typography>
                            </Stack>
                          </Alert>
                          ))
                        }
                        </Stack>
                  </Scrollbar>
                </Stack>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </DialogContent>
      </CustomDialog>
    </>
  )
}