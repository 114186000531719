// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Divider, Link } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
//
import { APPNAME } from 'src/utils/constants';
import SettingPopover from './SettingPopover';
import ConnectWallerPopover from './ConnectWallerPopover';
import NetworkPopover from './NetworkPopover';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PACKAGE, PACKAGE_COLOR } from 'src/constants/package';
import Iconify from 'src/components/Iconify';
import TestingLabel from 'src/components/TestingLabel';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.standard,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({ isCollapse = false, verticalLayout = false }: Props) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const dividerColor = isLight ? theme.palette.grey[100] : theme.palette.grey[500];
  const isMobile = useResponsive('down', 'sm');
  const { user } = useAuth();
  const { translate } = useLocales();

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }} alignItems="center">
          <Link
            href="/"
            underline="none"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}
          >
            <Logo sx={{ width: '32px' }} />
            {!isMobile && (
              <Typography variant="h5" color="primary.main">
                {APPNAME}
              </Typography>
            )}
          </Link>
          {!isMobile && user && (
            <>
              <Typography
                variant="body2"
                color={PACKAGE_COLOR[user.package]}
                sx={{
                  fontWeight: theme.typography.fontWeightLight,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <Iconify icon="ci:dot-05-xl" />
                {translate(`package.${PACKAGE[user.package]}`)}
              </Typography>
            </>
          )}
          {/* {!isMobile && <TestingLabel />} */}
        </Stack>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 2 }}>
          <NetworkPopover />
          <ConnectWallerPopover />
          <SettingPopover />
        </Stack>
      </Toolbar>
      <Divider sx={{ borderColor: dividerColor }} />
    </RootStyle>
  );
}
