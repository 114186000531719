import { Box, CircularProgress, styled, SxProps } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  isShow?: boolean;
  children: ReactNode;
  sx?: SxProps;
};

const LoadingOverlayBox = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.box-overlay': {
    transition: 'opacity .6s',
  },
  '&.show-loading .box-overlay': {
    opacity: .5,
    // background: "linear-gradient(90deg, rgba(190, 190, 190, .2) 25%, rgba(129, 129, 129, .24) 37%, rgba(190, 190, 190, .2) 63%)",
    // backgroundSize: "400% 100%",
    // animation: "loading-animation 1.4s ease-in infinite",
    // '&::after': {
    //   content: '""',
    // },
  },
  // "@keyframes loading-animation": {
  //   "0%": {
  //     opacity: .3
  //   },
  //   "50%": {
  //     opacity: .6
  //   },
  //   "100%": {
  //     opacity: .3
  //   }
  // }
}));

const LoadingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
  alignItems: 'start',
  zIndex: '2',
}));

const Loading = styled('div')(({ theme }) => ({
  maxHeight: '400px',
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  alignItems: 'center',
}));

export default function LoadingOverlay({ isShow = false, children, ...other }: Props) {
  return (
    <LoadingOverlayBox className={isShow ? 'show-loading' : ''}>
      {isShow && <LoadingWrapper>
        <Loading>
          <CircularProgress />
        </Loading>
      </LoadingWrapper>
      }
      <Box className="box-overlay" sx={{ flex: '1'}}>
        {children}
      </Box>
    </LoadingOverlayBox>
  )
}