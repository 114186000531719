import { Box, SxProps, Theme, BoxProps } from '@mui/material';
import SVG from 'react-inlinesvg';
import _ from 'lodash';

export interface CustomSvgIconProps extends BoxProps {
  src?: string;
  icon?: string;
  // color?: string | ((theme: Theme) => string);
}
export default function CustomSvgIcon({
  icon,
  src,
  sx,
  ...others
}: CustomSvgIconProps) {

  // const theme = useTheme();

  if (icon) {
    src = `/assets/icons/custom/${icon}.svg`
  }

  let fill = 'currentColor';
  // let sx: SxProps = {};
  // if (color) {
  //   if (typeof color !== 'string') {
  //     color = color(theme);
  //   }
  //   fill = color;
  // }
  let mySx: SxProps<Theme> = {
    'path': {
      fill,
    },
  };

  if (sx) {
    mySx = _.merge({}, sx, mySx);
  }

  return (
    // <Icon><img src={src} alt="icon" /></Icon>
    // <SVG src={src!} />
    <Box component={SVG} src={src!} sx={mySx} {...others} />
    // <Icon sx={{ mask: `url("${src}")` }} />
  )
}