import axios from './axios';
import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';

const axiosMock = new MockAdapter(axios as unknown as AxiosInstance, {
  onNoMatch: 'passthrough',
  delayResponse: 800,
});

export default axiosMock;
