export const enUS = {
  "header": {
    "mode": {
      "light": "Light",
      "dark": "Dark",
    },
    "language": "Language",
    "dark_theme": "Dark Theme",
    "setting_list": {
      "my_referral_list": "My Referral List",
      "sponsor_setting": "Sponsor Setting",
      "transaction_history": "Transaction History",
      "my_network": "My Network",
      "announcement": "Announcement",
    },
  },
  "form": {
    "label": {
      "referrals_address": "Referral's Address",
      "search": "Search",
      "start_date": "Start Date",
      "end_date": "End Date",
    }
  },
  "connect": {
    "connect_wallet": "Connect Wallet",
  },
  "error": {
    "went_wrong": "Opps, failed to connect",
    "check_internet": "Please check your internet connection or try again later",
    "insufficient_balance": "Insufficient Balance",
  },
  "notification_message": {
    "on_create": "{{title}} Added Successfully!",
    "on_edit": "{{title}} Edited Successfully!",
    "on_delete": "{{title}} Deleted Successfully!",
    "on_copied": "{{title}} Copied Successfully!",
  },
  "page_title": {
    "home": "Home",
    "announcement": "Announcement",
    "transaction_history": "Transaction History",
    "term_conditions": "Terms & Conditions",
    "privacy_policy": "Privacy Policy",
  },
  "my_referral_list": {
    "referral_link": "Referral Link",
  },
  "my_network": {
    "referees": "Referees",
    "refer_to_friend": "Refer to your friends and earn some bonuses!",
    "your_gp_sales": "Your Group Sales",
    "your_referees_gp_sales": "Your Referees' Group Sales",
  },
  "footer": {
    "copyright": "©{{year}} {{appname}}. All Rights Reserved",
    "follow_us": "Follow Us",
  },
  "common": {
    "total_mining_power": "Total Mining Power",
    "personal_mining_power": "Personal Mining Power",
    "single_token_package": "Single Token Package",
    "multiple_token_package": "Multiple Token Package",
    "mine_mstake": "Mine MSTAKE",
    "transfer": "Transfer",
    "history": "History",
    "purchase": "Purchase",
    "yesterday": "Yesterday",
    "today": "Today",
    "view_history": "View History",
    "manage_wallet": "Manage Wallet",
    "withdraw": "Withdraw",
    "metastake_wallet": "MetaStake Wallet",
    "deposit": "Deposit",
    "withdrawal": "Withdrawal",
    "staking_bonus": "Staking Bonus",
    "airdrop": "AirDrop",
    "sales": "Sales",
    "mining_bonus": "Mining Bonus",
    "copy_link": "Copy Link",
    "share_qr": "Share QR",
    "save": "Save",
    "review": "Review",
    "reset": "Reset",
    "filter": "Filter",
    "confirm": "Confirm",
    "balance": "Balance",
    "total_purchase": "Total Purchase",
    "back": "Back",
    "next": "Next",
    "lock": "Lock",
    "locked_rate": "Locked Rate",
    "purchase_successful": "Purchase Successful!",
    "select_wallet": "Select Wallet",
    "purchase_amount": "Purchase Amount",
    "search_name": "Search Name",
    "close": "Close",
    "bonus": "Bonus",
    "address": "Address",
    "receiver_address": "Receiver Address",
    "add_wallet": "Add Wallet",
  },
  "home": {
    "min_purchase_caption": "Minimum Purchase is {{value}} USD",
    "estimated_lock_rate": "Estimated Lock Rate (USD)",
    "estimated_lock_rate_desc": "Please lock the rate before proceed to purchase package",
    "lock_time_remaining": "Lock Time Remaining",
    "transaction_num": "Transaction Number: {{value}}",
    "lock_timeout_msg": "Lock rate times up. Please lock the rate again",
    "primary_token_min_percent_msg": "*{{token}} wallet MIN {{value}}% use for payment",
    "secondary_token_min_percent_msg": "*Other wallet MAX {{value}}% use for payment",
    "wallet_list_erc20": "Wallet List - ERC20",
    "estimate_rate": "Estimate Rate: {{rate}}",
  },
  "table": {
    "wallet": "Wallet",
    "date": "Date",
    "status": "Status",
    "amount": "Amount",
    "admin_fee": "Admin Fee",
    "net_amount": "Net Amount",
    "from_wallet": "From Wallet",
    "to_wallet": "To Wallet",
    "amount_musd": "Amount (MUSD)",
    "airdrop": "Airdrop",
    "type": "Type",
    "walletCode": "Wallet Code",
    "showing_rows_of": "Showing {{currentRow}} rows of {{totalRow}}",
    "showing_rows": "Showing rows",
    "actions":"Actions",
  },
  "status": {
    "COMPLETED": "Completed",
    "PENDING": "Pending",
    "FAILED": "Failed",
    "UNKNOWN": "Unknown",
  },
  "sales_package": {
    "FIRST": "First Package",
    "TOP_UP": "Top Up Package"
  },
  "package": {
    "ACTIVATED": "Package Activated",
    "NO_PACKAGE": "No Package",
  },

};

export type ILang = typeof enUS;

export default enUS;
