import { useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import LanguagePopover from './LanguagePopover';
import { ModeSwitch } from './ModeSwitch';
import useResponsive from 'src/hooks/useResponsive';
import ReferralPopover from './ReferralPopover';
import SponsorPopover from './SponsorPopover';
import MyNetworkPopover from './MyNetworkPopover';
import AnnoucementTab from './AnnoucementTab';
import TransactionTab from './TransactionTab';
import { Drawer, MenuItem, Stack, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { NAVBAR } from 'src/config';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { APPNAME } from 'src/utils/constants';
import { PACKAGE_COLOR, PACKAGE } from 'src/constants/package';
import useLocales from 'src/hooks/useLocales';
import TestingLabel from 'src/components/TestingLabel';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;

// ----------------------------------------------------------------------
export default function SettingPopover() {
  const { isAuthenticated } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const isMobile = useResponsive('down', 'sm');
  const { user } = useAuth();
  const theme = useTheme();
  const { translate } = useLocales();
  const isLight = theme.palette.mode === 'light';

  const moreIconColor = isLight ? theme.palette.grey[500] : 'text.primary';
  const borderColor = isLight ? theme.palette.grey[50] : theme.palette.grey[500];

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { isCollapse } = useCollapseDrawer();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const renderContent = (
    <>
      <MenuItem>
        <LanguagePopover />
      </MenuItem>
      <MenuItem>
        <ModeSwitch />
      </MenuItem>
      {isAuthenticated && (
        <>
          <MenuItem>
            <ReferralPopover />
          </MenuItem>
          <MenuItem>
            <SponsorPopover />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <TransactionTab />
          </MenuItem>
          <MenuItem>
            <MyNetworkPopover />
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <AnnoucementTab />
          </MenuItem>
        </>
      )}
    </>
  );

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          border: '1px solid',
          borderColor: borderColor,
          borderRadius: '8px',
          minWidth: '42px',
          // padding: "11px 22px",
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
        size={isDesktop ? 'medium' : 'small'}
      >
        <Iconify icon={'eva:more-horizontal-fill'} color={moreIconColor} width={20} height={20} />
      </IconButtonAnimate>

      {!isMobile ? (
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{
            mt: 1.5,
            ml: 0.75,
            width: 320,
            py: theme.spacing(2),
            '& .MuiMenuItem-root': {
              px: 1.5,
              height: ITEM_HEIGHT,
              borderRadius: 0.75,
            },
          }}
        >
          {renderContent}
        </MenuPopover>
      ) : (
        <Drawer
          open={Boolean(open)}
          onClose={handleClose}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          <Stack sx={{ height: '100vh' }}>
            <Stack
              spacing={3}
              sx={{
                pt: 3,
                pb: 2,
                px: theme.spacing(3.5),
                flexShrink: 0,
                ...(isCollapse && { alignItems: 'center' }),
              }}
            >
              <Stack direction="row" alignItems="center" spacing={2} sx={{ minHeight: '40px' }}>
                <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }} alignItems="center">
                  <Logo sx={{ width: '32px' }} />
                  <Typography variant="h5" color="primary.main">
                    {APPNAME}
                  </Typography>
                </Stack>
              </Stack>

              {user && (
                <Typography
                  variant="body2"
                  color={PACKAGE_COLOR[user.package]}
                  sx={{
                    fontWeight: theme.typography.fontWeightLight,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <Iconify icon="ci:dot-05-xl" />
                  {translate(`package.${PACKAGE[user.package]}`)}
                </Typography>
              )}

              {/* <TestingLabel /> */}
            </Stack>
            <Scrollbar
              sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
                px: theme.spacing(2),
              }}
            >
              {renderContent}
            </Scrollbar>
          </Stack>
        </Drawer>
      )}
    </>
  );
}
