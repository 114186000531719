import { Theme } from '@mui/material/styles';
import { FORM_PROVIDER_READONLY_LABEL } from 'src/constants';

// ----------------------------------------------------------------------

export default function Radio(theme: Theme) {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled,
            [`&.${FORM_PROVIDER_READONLY_LABEL}`]: {
              color: theme.palette.text.secondary,
              '&.Mui-checked': {
                color: theme.palette.primary.main,
              },
            }
          },
          svg: {
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20
            }
          }
        }
      }
    }
  };
}
