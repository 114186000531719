import { Theme } from '@mui/material/styles';
import { FORM_PROVIDER_READONLY_LABEL } from 'src/constants/className';

// ----------------------------------------------------------------------

export default function ControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          [`&.${FORM_PROVIDER_READONLY_LABEL} > .MuiFormControlLabel-label.Mui-disabled`]: {
            color: theme.palette.text.primary,
          }
        },
        label: {
          ...theme.typography.body2,
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1)
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        },
        root: {
          color: theme.palette.text.disabled
        }
      }
    }
  };
}
