// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabel, FormGroup, FormControlLabelProps, FormHelperText } from '@mui/material';
import { useContext } from 'react';
import { FormContext } from './FormProvider';
import { Spec } from './YupSpecs';
import { FORM_PROVIDER_READONLY_LABEL } from 'src/constants/className';

// ----------------------------------------------------------------------

export interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  label?: React.ReactNode;
}

export function RHFCheckbox({
  name,
  disabled,
  className = '',
  label = '',
  ...other
}: RHFCheckboxProps) {
  const { control } = useFormContext();
  const thing = useContext(FormContext);

  const { yupSpecs, readOnly } = thing;
  let spec: Spec = {};
  if (yupSpecs) {
    spec = yupSpecs.getSpec(name);
  }

  let readOnlyClass = '';
  if (readOnly) {
    readOnlyClass = FORM_PROVIDER_READONLY_LABEL;
    className += ' ' + readOnlyClass;
    disabled = true;
  }

  return (
    // <FormControlLabel
    //   control={
    //     <Controller
    //       name={name}
    //       control={control}
    //       render={({ field, fieldState: { error } }) =><FormControl>

    //       <Checkbox {...field} checked={field.value} {...spec} />
    //         {/* {!!error && (
    //           <FormHelperText error>
    //             {error.message}
    //           </FormHelperText>
    //         )} */}
    //     </FormControl>
    //     }
    //       />
    //     }
    //     {...other}
    //     />
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControlLabel
            {...spec}
            label={label || spec.label}
            control={<Checkbox {...field} checked={field.value} className={readOnlyClass} />}
            disabled={disabled}
            className={className}
            {...other}
          />
          {
            !!error && (
              <FormHelperText error>
                {error.message}
              </FormHelperText>
            )
          }
        </>
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: string[];
}

export function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();
  const thing = useContext(FormContext);

  const { yupSpecs } = thing;
  let spec: Spec = {};
  if (yupSpecs) {
    spec = yupSpecs.getSpec(name);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                    {...spec}
                  />
                }
                label={spec.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
