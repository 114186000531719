import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logoSrc = require('../assets/img/logo.png');

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ display: 'flex', alignContent: 'center', width: 40, ...sx }}>
      <img src={logoSrc} alt="" />
    </Box>
  );


  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
