import { ReactNode, useEffect } from 'react';
import { FormProvider } from "./hook-form";
import { Props as FormProviderProps } from 'src/components/hook-form/FormProvider';
import { Subscription as RHFSubscription } from 'react-hook-form/dist/utils/createSubject';
import _ from 'lodash';
// ----------------------------------------------------------------------

interface Props extends FormProviderProps {
  children: ReactNode;
  onFilter: (formData: any) => void;
  delayKeys?: string[];
  delay?: number;
}

export default function FilterForm({
  children,
  onFilter,
  delayKeys = ['s'],
  delay = 800,
  ...others
}: Props) {

  const {
    handleSubmit,
    watch,
    formState: { isValid },
  } = others.methods;

  useEffect(() => {
    let delayTimeout: any;
    let subscription: RHFSubscription;

    // only listen change if form is valid
    if (isValid) {
      // trigger filter if form already have value when valid
      const values = others.methods.getValues();
      if (!_.isEmpty(values)) {
        onFilter(values);
      }

      subscription = watch((value, { name, type }) => {
        if (name !== undefined && delayKeys.indexOf(name) > -1) {
          if (delayTimeout) {
            clearTimeout(delayTimeout);
          }
          const timeout = setTimeout(() => {
            onFilter(value);
          }, delay);
          delayTimeout = timeout;
        } else {
          onFilter(value);
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }
    };
  }, [watch, isValid]);

  const onSubmit = (data: any) => {
    onFilter({ ...data.data });
  };
  return (
    <FormProvider {...others} onSubmit={handleSubmit(onSubmit)}>
      {children}
    </FormProvider>
  )
}