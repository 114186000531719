// import { ObjectSchema } from 'yup';
import * as Yup from 'yup';
// import Reference from 'yup/lib/Reference';
// import Lazy from 'yup/lib/Lazy';

export interface Spec {
  label?: string;
  required?: boolean;
  // placeholder?: string;
}

export interface Specs {
  [key: string]: Spec;
}

const dummy = Yup.object().shape;
// type shape = Parameters<typeof dummy>[0];
// export type YupSchema = ObjectSchema<shape>;
export type YupSchema = ReturnType<typeof dummy>;

// const LoginSchema = Yup.object().shape({
//     email: Yup.string().email().required().label('fff'),
//     password: Yup.string().required().label('Password'),
// });

// const schema: YupSchema2 = LoginSchema;


const isSchema = (val: any): val is Yup.AnySchema<any, any, any> => val.spec !== undefined;

export class YupSpecs {
  private _specs: Specs = {};

  constructor(schema: YupSchema) {
    Object.keys(schema.fields).forEach((name) => {
      const field = schema.fields[name];
      if (isSchema(field)) {
        const { spec } = field;
        this._specs[name] = {
          label: spec.label,
          required: spec.optional === false,
          // placeholder: spec.label,
        };
      }
    });
  }

  getSpec = (name: string): Spec => this._specs[name] || {};
}