import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackBarState } from 'src/@types/snackbar';
import _ from 'lodash';
import { AlertColor } from '@mui/material';

// ----------------------------------------------------------------------

const initialState: SnackBarState = {
  open: false,
  message: '',
  severity: 'success',
};

export interface SnackBarData {
  message: string;
  severity: AlertColor;
}

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackBar(state, action: PayloadAction<SnackBarData>) {
      state.open = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    closeSnackBar(state) {
      state.open = false;
      // state.message = '';
    },
  },
});

// Reducer
export default slice.reducer;

// export function openSnackBar(data: SnackBarData) {
//   return () => {
//     dispatch(slice.actions.openSnackBar(data));
//   };
// }

// export function closeSnackBar() {
//   return () => {
//     dispatch(slice.actions.closeSnackBar());
//   };
// }

// Actions
export const {
  openSnackBar,
  closeSnackBar
} = slice.actions
