import axios from 'src/utils/axios';
import {
  Card,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import mock from 'src/utils/axiosMock';
import LoadingOverlay from 'src/components/LoadingOverlay';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import LoadReload from 'src/components/LoadReload';
import useAuth from 'src/hooks/useAuth';
import { API_LIST } from 'src/constants';

interface WalletList {
  walletId: string;
  walletName: string;
  walletIcon: string;
}

interface Props {
  setLoadingBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: () => void;
}

export default function ConnectWallet(props: Props) {
  const { login } = useAuth();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'sm');

  const [dataSubs, setDataSubs] = useState<Subscription>();
  const [loading, setLoading] = useState(false);
  const [walletList, setWalletList] = useState<WalletList[]>([]);
  const [retry, setRetry] = useState(false);

  mock.onGet(API_LIST.CONNECT_WALLET_LIST).reply((config) => [
    200,
    {
      data: [
        {
          walletId: '1',
          walletName: 'MetaMask',
          walletIcon: '/assets/wallet-icon/MetaMask.png',
        },
        // {
        //   walletId: '2',
        //   walletName: 'Wallet Connect',
        //   walletIcon: '/assets/wallet-icon/MetaMask.png',
        // },
      ],
    },
  ]);

  const getWalletList = () => {
    if (dataSubs) {
      dataSubs.unsubscribe();
    }

    let url = API_LIST.CONNECT_WALLET_LIST;
    setLoading(true);
    const currentSub = axios
      .get(url)
      .pipe(
        catchError((err) => {
          setRetry(true);
          return throwError(() => err);
        })
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe((res) => {
        setWalletList(res.data.data);
      });
    setDataSubs(currentSub);
  };

  useEffect(() => {
    getWalletList();
  }, []);

  if (retry) {
    return <LoadReload data={walletList} retryState={[retry, setRetry]} init={getWalletList} />;
  }

  const handleConnect = (walletId: string) => {
    if (props.setLoadingBtn) {
      props.setLoadingBtn(true);
    }
    login(walletId);
    if (props.closeModal) {
      props.closeModal();
    }
  };

  return (
    <Card sx={{ minWidth: isDesktop ? 500 : 275 }} variant="outlined">
      <Typography variant="h6" sx={{ fontWeight: '500' }}>
        {translate('connect.connect_wallet')}
      </Typography>
      <LoadingOverlay isShow={loading}>
        <List>
          {walletList.map((element, index) => (
            <ListItem
              disablePadding
              key={`wallet_${index}`}
              sx={{
                my: 1,
              }}
            >
              <ListItemButton
                sx={{
                  border: 'solid 1px #EBEBEC',
                  borderRadius: '16px',
                  py: 2,
                }}
                onClick={() => handleConnect(element.walletId)}
              >
                <ListItemAvatar>
                  <Image src={element.walletIcon} width={24} height={24} />
                </ListItemAvatar>
                <ListItemText primary={element.walletName} />
                <ListItemIcon>
                  <Iconify icon={'eva:chevron-right-outline'} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </LoadingOverlay>
    </Card>
  );
}
