import { useState } from 'react';
// @mui
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Radio, Stack, Typography, useTheme } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import { IconButtonAnimate } from '../../../components/animate';
import { responsiveFontSizes } from 'src/utils/getFontValue';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const theme = useTheme();
  const { allLang, currentLang, onChangeLang, translate } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button variant='text'
        sx={{
          textTransform: 'unset',
          color: 'text.primary',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'unset',
          },
          pl: 0,
          pr: 1
        }}
        fullWidth
        endIcon={
          // <IconButtonAnimate
          //   // onClick={handleOpen}
          //   sx={{
          //     width: 40,
          //     height: 40,
          //     ...(open && { bgcolor: 'action.selected' }),
          //   }}
          // >
          //   <Image disabledEffect visibleByDefault={true} src={currentLang.iconCircle} alt={currentLang.label} />
          // </IconButtonAnimate>
          <Box
            sx={{
              width: 40,
              height: 40,
              p: 1,
            }}
          >
            <Image disabledEffect visibleByDefault={true} src={currentLang.iconCircle} alt={currentLang.label} />
          </Box>
        }
        disableRipple
      onClick={handleOpen}
      >
        <Typography sx={{ color: 'text.primary' }}>{currentLang.label}</Typography>
      </Button>

      <Dialog
        onClose={handleClose}
        open={Boolean(open)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {translate('header.language')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
          // sx={{
          //   position: 'absolute',
          //   right: 8,
          //   top: 8,
          //   color: (theme) => theme.palette.grey[500],
          // }}
          >
            <Iconify icon={'eva:close-fill'} color="text.primary" width={32} height={32} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            {allLang.map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === currentLang.value}
                onClick={() => {
                  onChangeLang(option.value);
                  handleClose();
                }}
                sx={{
                  p: theme.spacing(2),
                  borderRadius: '16px',
                  justifyContent: 'space-between'
                }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <Image
                    disabledEffect
                    visibleByDefault={true}
                    alt={option.label}
                    src={option.iconCircle}
                    sx={{
                      width: 28,
                      mr: 2,
                    }}
                  />

                  {option.label}
                </Stack>
                <Radio checked={ option.value === currentLang.value } />
              </MenuItem>
            ))}
          </Stack>
        </DialogContent>
      </Dialog>

      {/* <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                handleClose();
              }}
            >
              <Image
                disabledEffect
                visibleByDefault={true}
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover> */}
    </>
  );
}
