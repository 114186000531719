export enum STATUS {
  COMPLETED = 1,
  PENDING = 2,
  FAILED = 3,
  UNKNOWN = -1,
}

export const STATUS_COLOR = {
  [STATUS.COMPLETED]: 'success.main',
  [STATUS.PENDING]: 'primary.main',
  [STATUS.FAILED]: 'error.main',
  [STATUS.UNKNOWN]: 'secondary.main',
}
