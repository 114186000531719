import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ReactNode } from 'react';
import useLocales from 'src/hooks/useLocales';
import Iconify from './Iconify';


export interface LoadReloadProps {
  children?: ReactNode;
  init: () => void;
  data: any;
  retryState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  showEmpty?: boolean;
  emptyNode?: ReactNode;
}

export default function LoadReload({
  children,
  init,
  data,
  retryState,
  showEmpty = false,
  emptyNode,
}: LoadReloadProps) {
  const [retry, setRetry] = retryState;
  const { translate } = useLocales();

  const onRetry = () => {
    setRetry(false);
    init();
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {/* loading */}
      {!data && !retry && (<>
        {children && (
          <>{children}</>
        )}

        {!children && (
          <Box sx={{ display: 'flex', padding: '3rem' }}>
            <CircularProgress />
          </Box>
        )}
      </>)}

      {/* retry */}
      {retry && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>{translate('error.went_wrong')}</Typography>
          <Typography>{translate('error.check_internet')}</Typography>
          <Button
            sx={{ marginTop: '1rem' }}
            variant="contained"
            startIcon={<Iconify icon="ic:baseline-refresh" />}
            onClick={onRetry}
          >
            {translate('common.retry')}
          </Button>
        </Box>
      )}

      {/* empty */}
      {showEmpty && data && data.length === 0 && (<>
        {emptyNode && (<>{emptyNode}</>)}
        {!emptyNode && (
          <Box sx={{ textAlign: 'center' }}>
            {translate('common.no_results')}
          </Box>
        )}
      </>)}
    </Box>
  );
}