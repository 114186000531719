import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { isValidToken, setSession } from '../utils/jwt';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
  NetworkData,
  UserData,
} from '../@types/auth';
import { EXTERNAL_URL } from 'src/constants';
import { PACKAGE } from 'src/constants/package';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  Network = 'NETWORK',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.Network]: {
    network: NetworkData;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: true,
  isInitialized: false,
  user: null,
};

const formatMockUser = (user: any) => {
  const data: UserData = {
    ...user,
    network: {
      networkId: '123',
      networkName: 'Binance Smart Chain',
      networkIcon: '/assets/network-icon/BinanceSmartChain.png',
    },
    gp_sales: '19847286.12920300009',
    package: '1',
  };

  return data;
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case 'NETWORK':
      return {
        ...state,
        user: {
          ...state.user,
          network: action.payload.network,
        } as AuthUser,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const user = formatMockUser('');

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user: {
                ...user,
                address: accessToken,
                referral_link: EXTERNAL_URL.REFERRAL_LINK + accessToken,
              },
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (walletId: string) => {
    const mockAddress: any = {
      '1': '0xA07798772512239C80221730907E5b1e7E472365',
      '2': '0xA07798772512239C80221730907E5b1e7E472365',
    };
    const accessToken = mockAddress[walletId];
    const user = formatMockUser('');
    setSession(accessToken);

    dispatch({
      type: Types.Login,
      payload: {
        user: {
          ...user,
          address: accessToken,
          referral_link: EXTERNAL_URL.REFERRAL_LINK + accessToken,
        },
      },
    });
  };

  const network = async (networkData: NetworkData) => {
    const user = formatMockUser('');
    dispatch({
      type: Types.Network,
      payload: {
        network: networkData,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        network,
        // register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
