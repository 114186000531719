import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';
import moment from 'moment';

import { LANGS, DEFAULT_LANG, LangData } from 'src/locales';
import useSettings from './useSettings';
import { setHeader } from 'src/utils/axios';

// ----------------------------------------------------------------------

export const defaultLangData = LANGS[0];
export const getLangData = (lang: string) => LANGS.find((_lang) => _lang.value === lang) || defaultLangData;
export const setupNewLangData = (langData: LangData) => {
  // update libs locale
  const { _additional, ...yupLang } = langData.libs.yup.lang;
  setLocale(yupLang);
  moment.locale(langData.libs.moment.value);

  // update header
  setHeader('language', langData.value);
}

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { themeDirection, onChangeDirection } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng') || DEFAULT_LANG;
  const currentLang = getLangData(langStorage);

  const handleChangeLanguage = (newLang: string) => {
    const currentLang = i18n.language;
    if (newLang === currentLang) {
      return;
    }
    const langData = getLangData(newLang);
    setupNewLangData(langData);

    if (langData.direction !== themeDirection) {
      onChangeDirection(langData.direction);
    }
    // i18n.changeLanguage(newlang);
    setTimeout(() => i18n.changeLanguage(newLang));
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
