// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Stack,
  TextField,
  StackProps,
  InputAdornment,
  useTheme,
  Box,
  TextFieldProps,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useContext, useEffect, useState } from 'react';
import { FormContext } from './FormProvider';
import { Spec } from './YupSpecs';
import useResponsive from 'src/hooks/useResponsive';
import { MobileDatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import CustomSvgIcon from '../CustomSvgIcon';

// ----------------------------------------------------------------------

interface IProps extends StackProps{
  name: string;
}

export default function RHFDatePicker({
  name,
  defaultValue,
  ...other
}: IProps) {
  const { control, setValue, getValues } = useFormContext();

  const thing = useContext(FormContext);

  const { yupSpecs, readOnly } = thing;

  const [open, setOpen] = useState(false);

  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const icon = <CustomSvgIcon icon={'calendar'} color={isLight ? theme.palette.grey[500] : "secondary.main"} width={18} height={18} />

  let spec: Spec = {};
  if (yupSpecs) {
    spec = yupSpecs.getSpec(name);
  }

  useEffect(() => {
    const currentValue = getValues(name);
    if (!currentValue && currentValue !== defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue])
  return (
    <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack {...other}>
              <Box component={isMobile ? MobileDatePicker : DesktopDatePicker}
                {...field}
                onChange={(newValue: any) => {
                  setValue(name, newValue);
                  field.onChange(newValue);
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    onClick={(e) => setOpen(true)}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: spec.label
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {icon}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                readOnly={readOnly}
                inputFormat="dd/MM/yyyy"
                InputAdornmentProps={{ position: 'start' }}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
              />
              {/* <DatePicker
                inputFormat="tt.mm.yyyy"
                label=""
                {...field}
                onChange={(newValue) => {
                  setValue(name, newValue);
                  field.onChange(newValue);
                }}
                // value={value}
                // onChange={(newValue) => setValue(newValue)}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "xxx ccc"
                      }}
                    />
                  );
                }}
              /> */}
            </Stack>
          </LocalizationProvider>
        )}
    />
  );
}
