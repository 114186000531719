/* eslint-disable no-template-curly-in-string */
import {
  // mixed,
  // string,
  // number,
  // date,
  // boolean,
  // object,
  // array,
} from 'yup';
import notType from './notType';
import { ILangYup } from './yup.interface';

export const koKR: ILangYup = {
  mixed: {
    default: '잘못된 ${path}',
    required: '${path}는 필수 항목입니다.',
    defined: '${path}를 정의해야 합니다.',
    notNull: '${path}는 비워둘 수 없습니다.',
    oneOf: '${path}는 다음 값 중 하나여야 합니다. ${values}',
    notOneOf: '${path}는 다음 값 중 하나일 수 없습니다. ${values}',
    notType: (params) => notType(params, koKR, {
      cast: ' (${value} 값에서 변환).',
      mixed: '${path}는 구성 유형과 일치해야 합니다. 유효성 검사 값은 ${value}입니다.',
      not_mixed: '${path}는 ${value}이어야 합니다.',
      unknown_type: 'Unknown',
      full_stop: '.',
    }),
  },
  string: {
    length: '${path}는 정확히 ${length}자여야 합니다.',
    min: '${path}는 최소 ${min}자를 포함해야 합니다.',
    max: '${path}는 최대 ${max}자까지만 사용할 수 있습니다.',
    matches: '${path}는 "${regex}"와 일치해야 합니다.',
    email: '${path}는 유효한 이메일이어야 합니다.',
    url: '${path}는 유효한 URL이어야 합니다.',
    uuid: '${path}는 유효한 UUID여야 합니다.',
    trim: '${path}는 잘린 문자열이어야 합니다.',
    lowercase: '${path}는 소문자 문자열이어야 합니다.',
    uppercase: '${path}는 대문자 문자열이어야 합니다.',
  },
  number: {
    min: '${path}는 ${min}보다 크거나 같아야 합니다.',
    max: '${path}는 ${max}보다 작거나 같아야 합니다.',
    lessThan: '${path}는 ${less}보다 작아야 합니다.',
    moreThan: '${path}는 ${more}보다 커야 합니다.',
    positive: '${path}는 양수여야 합니다.',
    negative: '${path}는 음수여야 합니다.',
    integer: '${path}는 정수여야 합니다.',
  },
  date: {
    min: '${path} 필드는 ${min} 이후여야 합니다.',
    max: '${path} 필드는 ${max}보다 오래되어야 합니다.',
  },
  boolean: {
    isValue: '${path} 필드는 ${value}여야 합니다.',
  },
  object: {
    noUnknown: '${path} 필드에 지정되지 않은 키가 있습니다: ${unknown}',
  },
  array: {
    min: '${path} 필드에는 최소 ${min} 항목이 있어야 합니다.',
    max: '${path} 필드는 ${max} 항목보다 작거나 같아야 합니다.',
    length: '${path}에는 ${length} 항목이 있어야 합니다.',
  },
  _additional: {
    _type: {
      ref: 'ref',
      mixed: '혼합',
      boolean: '부울 값',
      string: '끈',
      number: '숫자',
      date: '데이트',
      object: '목적',
      array: '정렬',
      tuple: '튜플',
      lazy: 'lazy',
    },
  },
};

export default koKR;
