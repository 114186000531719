import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// @mui
import { Stack, Typography, Button, SxProps } from '@mui/material';
//
import { ContentCopyOutlined } from '@mui/icons-material';
import { openSnackBar } from 'src/redux/slices/snackbar';
import { dispatch } from 'src/redux/store';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  value: string;
  copiedElement: string;
  showIcon?: boolean;
  buttonText: string;
  buttonProps?: SxProps;
};

export default function CopyClipboard({ value, copiedElement, showIcon = false, buttonText, buttonProps, ...other }: Props) {
  const [state, setState] = useState({
    value,
    copied: false,
  });
  const { translate } = useLocales();

  const onCopy = () => {
    setState({ ...state, copied: true });
    if (state.value) {
      let msg = translate('notification_message.on_copied', { title: copiedElement })
      dispatch(openSnackBar({ message: msg, severity: 'success' }));
    }
  };

  return (
      <CopyToClipboard text={state.value} onCopy={onCopy}>
      {/* <Tooltip title={translate('common.copy')}> */}
      <Button variant="outlined" sx={{
          ...buttonProps
        }}>
          <Stack direction="row" spacing={1}>
            {showIcon && <ContentCopyOutlined />}
            <Typography>{ buttonText }</Typography>
          </Stack>
      </Button>
       {/* </Tooltip> */}
     </CopyToClipboard>
  );
}
