import axios from 'src/utils/axios';
import {
  alpha,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import Image from 'src/components/Image';
import useResponsive from 'src/hooks/useResponsive';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { API_LIST } from 'src/constants';
import mock from 'src/utils/axiosMock';
import useAuth from 'src/hooks/useAuth';

const ITEM_HEIGHT = 64;

interface NetworkList {
  networkId: string;
  networkName: string;
  networkIcon: string;
}

export default function NetworkPopover() {
  const { user, network } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const iconColor = isLight ? theme.palette.grey[500] : 'text.primary';
  // const borderColor = isLight ? theme.palette.grey[50] : theme.palette.grey[500];
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [icon, setIcon] = useState<string>('eva:chevron-down-outline');

  const [dataSubs, setDataSubs] = useState<Subscription>();
  const [loading, setLoading] = useState(false);
  const [networkList, setNetworkList] = useState<NetworkList[]>([]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
    setIcon('eva:chevron-up-outline');
  };

  const handleClose = () => {
    setOpen(null);
    setIcon('eva:chevron-down-outline');
  };

  mock.onGet(API_LIST.NETWORK_LIST).reply((config) => [
    200,
    {
      data: [
        {
          networkId: '56',
          networkName: 'Binance Smart Chain',
          networkIcon: '/assets/network-icon/BinanceSmartChain.png',
        },
        {
          networkId: '1',
          networkName: 'Ethereum',
          networkIcon: '/assets/network-icon/Ethereum.png',
        },
      ],
    },
  ]);

  const getNetworkList = () => {
    if (dataSubs) {
      dataSubs.unsubscribe();
    }

    let url = API_LIST.NETWORK_LIST;
    setLoading(true);
    const currentSub = axios
      .get(url)
      .pipe(catchError((err) => throwError(() => err)))
      .pipe(finalize(() => setLoading(false)))
      .subscribe((res) => {
        setNetworkList(res.data.data);
      });
    setDataSubs(currentSub);
  };

  useEffect(() => {
    getNetworkList();
  }, []);

  if (!user) {
    return <></>;
  }

  const changeNetwork = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    currentNetworkId: string
  ) => {
    networkList.forEach((element) => {
      if (element.networkId === currentNetworkId) {
        network(element);
        // user.network = element;
        handleClose();
      }
    });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{
          // border: "1px solid",
          // borderColor: borderColor,
          color: 'text.primary',
          borderRadius: '8px',
          minWidth: '42px',
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
          textTransform: 'unset',
        }}
        color="secondary"
        disableRipple
        size={isDesktop ? 'medium' : 'small'}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1}>
            <Image src={user.network.networkIcon} width={24} height={24} />
            {isDesktop && <Typography>{user.network.networkName}</Typography>}
          </Stack>
          <Iconify icon={icon} color={iconColor} width={20} height={20} />
        </Stack>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          px: 1,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <List>
          {networkList.map((element, index) => (
            <ListItem
              disablePadding
              key={`wallet_${index}`}
              sx={{
                my: 1,
              }}
            >
              <ListItemButton
                sx={{
                  borderRadius: '8px',
                  py: 2,
                }}
                onClick={(event) => changeNetwork(event, element.networkId)}
              >
                <ListItemAvatar>
                  <Image src={element.networkIcon} width={24} height={24} />
                </ListItemAvatar>
                <ListItemText primary={element.networkName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </MenuPopover>
    </>
  );
}
