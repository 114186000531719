import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "src/redux/store";
import { closeSnackBar } from "src/redux/slices/snackbar";

export default function SnackBar() {
  const { open, message, severity } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSnackBar());
    // dispatch(openSnackBar({}))
  };


  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleClose} autoHideDuration={3000}>
      <Alert severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}