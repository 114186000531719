// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { useContext } from 'react';
import { FormContext } from './FormProvider';
import { Spec } from './YupSpecs';

// ----------------------------------------------------------------------

export interface IProps {
  name: string;
}

type Props = IProps & TextFieldProps;

export default function RHFTextField({
  name,
  InputProps = {},
  ...other
}: Props) {
  const { control } = useFormContext();
  const thing = useContext(FormContext);

  const { yupSpecs, readOnly } = thing;
  if (readOnly) {
    InputProps.readOnly = readOnly;
  }
  let spec: Spec = {};
  if (yupSpecs) {
    spec = yupSpecs.getSpec(name);
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      render={({ field, fieldState: { error } }) => (
        <TextField
          color='secondary'
          {...spec}
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          InputProps={InputProps}
          {...other}
          // aria-readonly="true"
        />
      )}
    />
  );
}
