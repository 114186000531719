import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, RouteObject } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import useAuth from 'src/hooks/useAuth';
import { PATH_AFTER_LOGIN } from 'src/config';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
   (
    <Suspense fallback={<LoadingScreen fullscreenLoading={false} />}>
      <Component {...props} />
    </Suspense>
  )
;

export default function Router() {
  const { isAuthenticated, isInitialized } = useAuth();
  const defaultRoute = isAuthenticated ? PATH_AFTER_LOGIN : PATH_AUTH.login;

  const initializingRoutes: RouteObject[] = [
    { path: '*', element: <LoadingScreen /> },
  ];

  const routes: RouteObject[] = [
    {
      path: '/',
      // element: <Navigate to="/dashboard/one" replace />,
      element: <Navigate to={defaultRoute} replace />,
    },
    {
      path: '/auth',
      element: (
        <GuestGuard>
          <DashboardLayout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: (<Login />) },
        // { path: 'register', element: (<GuestGuard><Register replace /></GuestGuard>) },
        // {
        //   path: 'register',
        //   children: [
        //     { element: <Navigate to="/auth/register/step1" replace />, index: true },
        //     { path: 'step1', element: <RegisterStep1 replace/> },
        //     // { path: 'five', element: <PageFive /> },
        //     // { path: 'six', element: <PageSix /> },
        //   ],
        // },
      ]
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.general.index} replace />, index: true },
        { path: 'index', element: <Dashboard /> },
      ],
    },
    {
      path: '/announcement',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/announcement', element: <Announcement /> },
      ],
    },
    {
      path: '/transaction-history',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/transaction-history', element: <TransactionHistory /> },
      ],
    },
    {
      path: '/terms-conditions',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/terms-conditions', element: <TermsCondition /> },
      ],
    },
    {
      path: '/privacy-policy',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/privacy-policy', element: <PrivacyPolicy /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(isInitialized ? routes : initializingRoutes);
  // return useRoutes(initializingRoutes);
}
// Login
const Login = Loadable(lazy(() => import('../pages/Login')))
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Announcement = Loadable(lazy(() => import('../pages/Announcement')));
const TransactionHistory = Loadable(lazy(() => import('../pages/TransactionHistory')));
const TermsCondition = Loadable(lazy(() => import('../pages/TermsCondition')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));