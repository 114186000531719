import { Observable, tap } from "rxjs";
import { HTTP_STATUS } from "src/constants";
import { openSnackBar } from "src/redux/slices/snackbar";
import { dispatch } from "src/redux/store";
import axios from 'src/utils/axios';
import useLocales from "./useLocales";

export default function useFormService() {
  const { translate } = useLocales();

  const submit = (formData: any, url: string, title: string, id?: string): Observable<any> => {

    let method = 'post';
    if (id) {
      url += `/${id}/edit`;
      method = 'put';
    }

    return axios.request({
      method: method,
      data: formData,
      url: url,
    }).pipe(tap((response) => {
      let msg = '';
      if (response.status === HTTP_STATUS.CREATED && method === 'post') {
        msg = translate('notification_message.on_create', { title: title })
      } else if (response.status === HTTP_STATUS.NOT_CONTENT && method === 'put') {
        msg = translate('notification_message.on_edit', { title: title })
      }
      
      if (msg) {
        dispatch(openSnackBar({ message: msg, severity: 'success' }));
      }
    }));
  };

  const deleteData = (url: string, id: string, title: string): Observable<any> => axios.delete(`${url}/${id}`)
    .pipe(tap((response) => {
      let msg = '';
      if (response.status === HTTP_STATUS.NOT_CONTENT) {
        msg = translate('notification_message.on_delete', { title: title })
      }

      if (msg) {
        dispatch(openSnackBar({ message: msg, severity: 'success' }));
      }
    }));

  return {
    submit,
    deleteData,
  };
}
