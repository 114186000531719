import * as Yup from 'yup';
import { Button, DialogContent, DialogTitle, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import LoadingOverlay from 'src/components/LoadingOverlay';
import useFormService from 'src/hooks/useFormService';
import { API_LIST } from 'src/constants';
import { finalize } from 'rxjs';
import mock from 'src/utils/axiosMock';
import CustomDialog from 'src/components/CustomDialog';

type FormValuesProps = {
  referral_address: string;
};

export default function SponsorPopover() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { translate } = useLocales();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const { submit } = useFormService();

  const closeIconColor = isLight ? theme.palette.grey[500] : theme.palette.grey[0];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const FormSchema = Yup.object().shape({
    referral_address: Yup.string().required().label(translate("form.label.referrals_address")),
  })
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  //mock
  const usersUri = API_LIST.SAVE_REFERRAL;
  const url = new RegExp(`${usersUri}`);
  mock.onPost(url)
    .reply((config) => [201]);

  const onSubmit = async (data: FormValuesProps) => {
    setLoading(true);
    let message_title = translate('form.label.referrals_address');
    let url = API_LIST.SAVE_REFERRAL;
    submit(data, url, message_title)
      .pipe(finalize(() => setLoading(false)))
      .subscribe((res) => {
        handleClose();
      })
  };

  return (
    <>
      <Button
        variant='text'
        sx={{
          textTransform: 'unset',
          color: 'text.primary',
          justifyContent: 'space-between',
          '&:hover': {
            backgroundColor: 'unset',
          },
          pl: 0,
          pr: 1
        }}
        fullWidth
        endIcon={
          <Iconify icon={'eva:chevron-right-outline'} />
        }
        disableRipple
        onClick={handleOpen}
      >
        <Typography sx={{ color: 'text.primary' }}>{translate('header.setting_list.sponsor_setting')}</Typography>
      </Button>

      <CustomDialog
        onClose={handleClose}
        open={Boolean(open)}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontWeight: theme.typography.fontWeightMedium
          }}
        >
          {translate('header.setting_list.sponsor_setting')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <Iconify icon={'eva:close-fill'} color={closeIconColor} width={32} height={32} />
          </IconButton>
        </DialogTitle>
        <LoadingOverlay isShow={loading}>
          <DialogContent>
            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(onSubmit)}
              yupSchema={FormSchema}
            >
              <Stack direction="column" spacing={2}>
                <RHFTextField name='referral_address'/>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {translate("common.save")}
                </LoadingButton>
              </Stack>
            </FormProvider>
          </DialogContent>
        </LoadingOverlay>
      </CustomDialog>
    </>
  )
}