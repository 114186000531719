import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getLangData, setupNewLangData } from 'src/hooks/useLocales';

import { DEFAULT_LANG, LANGS } from './constant';

//
// import enLocales from './en_us';
// import deLocales from './de.json';
// import frLocales from './fr.json';

// ----------------------------------------------------------------------

const resources: Resource = {};

LANGS.forEach((data) => {
  resources[data.value] = { translations: data.lang };
});

i18n.on('initialized', (opts) => {
  const currentLang = i18n.language;
  const langData = getLangData(currentLang);
  // setLocale(langData.yupLang);
  setupNewLangData(langData);
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
