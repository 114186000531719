import { Theme } from '@mui/material/styles';
import { FORM_PROVIDER_READONLY_LABEL } from 'src/constants';
//
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled,
            [`&.${FORM_PROVIDER_READONLY_LABEL}`]: {
              color: theme.palette.text.secondary,
              '&.Mui-checked': {
                color: theme.palette.primary.main,
              },
            }
          },
          // [`&.Mui-checked.Mui-disabled:not(.${FORM_PROVIDER_READONLY_LABEL}), &.Mui-disabled:not(.${FORM_PROVIDER_READONLY_LABEL})`]: {
          //   // color: theme.palette.action.disabled,
          //   color: 'red',
          // },
          // [`&.Mui-checked.Mui-disabled, &.Mui-disabled:not(.${FORM_PROVIDER_READONLY_LABEL})`]: {
          //   color: theme.palette.primary.main,
          // },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 24,
            height: 24,
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 20,
            height: 20,
          },
          svg: {
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20,
            },
          },
        },
      },
    },
  };
}
