import { Typography, TypographyProps, useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { APPNAME } from "src/utils/constants";

interface Props extends TypographyProps {}

export default function Copyright({ sx }: Props) {
    const { translate } = useLocales();
    const theme = useTheme();
    return (
        <Typography sx={{
            fontSize: {
                xs: theme.typography.caption,
                sm: theme.typography.body2,
                lg: theme.typography.caption
            },
            ...sx
        }}>
            {translate('footer.copyright', { appname: APPNAME, year: (new Date().getFullYear()) })}
            {/* © Copyright {APPNAME} {(new Date().getFullYear())}. All Rights Reserved */}
        </Typography>
    )
};