import { ILang } from './en_us';

export const koKR: ILang = {
  "header": {
    "mode": {
      "light": "빛",
      "dark": "어두운",
    },
    "language": "언어",
    "dark_theme": "어두운 테마",
    "setting_list": {
      "my_referral_list": "내 추천 목록",
      "sponsor_setting": "후원자 설정",
      "transaction_history": "거래 내역",
      "my_network": "내 네트워크",
      "announcement": "발표",
    },
  },
  "form": {
    "label": {
      "referrals_address": "추천인 주소",
      "search": "검색",
      "start_date": "시작일",
      "end_date": "종료일",
    }
  },
  "connect": {
    "connect_wallet": "지갑 연결",
  },
  "error": {
    "went_wrong": "죄송합니다. 연결하지 못했습니다.",
    "check_internet": "인터넷 연결을 확인하거나 나중에 다시 시도하세요.",
    "insufficient_balance": "잔액 불충분",
  },
  "notification_message": {
    "on_create": "{{title}}{{title}} 성공적으로 추가되었습니다!",
    "on_edit": "{{title}}이(가) 성공적으로 수정되었습니다!",
    "on_delete": "{{title}}이(가) 성공적으로 삭제되었습니다!",
    "on_copied": "{{title}} 성공적으로 복사되었습니다!",
  },
  "page_title": {
    "home": "홈페이지",
    "announcement": "공고",
    "transaction_history": "거래 내역",
    "term_conditions": "이용약관",
    "privacy_policy": "개인 정보 보호 정책",
  },
  "my_referral_list": {
    "referral_link": "추천 링크",
  },
  "my_network": {
    "referees": "Referees",
    "refer_to_friend": "친구를 추천하고 보너스를 받으세요!",
    "your_gp_sales": "귀하의 그룹 판매",
    "your_referees_gp_sales": "심판의 그룹 판매",
  },
  "footer": {
    "copyright": "©{{year}} {{appname}}. 판권 소유",
    "follow_us": "팔로우",
  },
  "common": {
    "total_mining_power": "총 컴퓨팅 파워",
    "personal_mining_power": "개인 컴퓨팅 파워",
    "single_token_package": "단일 통화 패키지",
    "multiple_token_package": "다중 통화 패키지",
    "mine_mstake": "발굴 MSTAKE",
    "transfer": "옮기다",
    "history": "역사",
    "purchase": "구입하다",
    "yesterday": "어제",
    "today": "오늘",
    "view_history": "기록 보기",
    "manage_wallet": "지갑 관리",
    "withdraw": "발췌",
    "metastake_wallet": "MetaStake 지갑",
    "deposit": "보증금",
    "withdrawal": "철수",
    "staking_bonus": "스테이킹 보너스",
    "airdrop": "공중 투하",
    "sales": "매상",
    "mining_bonus": "채굴 보너스",
    "copy_link": "링크 복사",
    "share_qr": "QR 공유",
    "save": "저장",
    "review": "리뷰",
    "reset": "초기화",
    "filter": "필터",
    "confirm": "확인하다",
    "balance": "균형",
    "total_purchase": "총 구매",
    "back": "뒤",
    "next": "다음",
    "lock": "잠금",
    "locked_rate": "고정 비율",
    "purchase_successful": "성공적인 구매!",
    "select_wallet": "지갑 선택",
    "purchase_amount": "구매 가격",
    "search_name": "검색 이름",
    "close": "폐쇄",
    "bonus": "보너스",
    "address": "주소",
    "receiver_address": "받는 사람의 주소",
    "add_wallet": "지갑 추가",
  },
  "home": {
    "min_purchase_caption": "최소 구매 금액: {{value}}",
    "estimated_lock_rate": "예상 고정 비율(USD)",
    "estimated_lock_rate_desc": "요금제를 진행하기 전에 가격을 잠그십시오.",
    "lock_time_remaining": "남은 잠금 시간",
    "transaction_num": "거래 번호: {{value}}",
    "lock_timeout_msg": "잠긴 가격 시간이 초과되었습니다. 가격을 다시 잠그십시오",
    "primary_token_min_percent_msg": "*{{token}} wallet MIN {{value}}% use for payment",
    "secondary_token_min_percent_msg": "*{{token}} wallet MAX {{value}}% use for payment",
    "wallet_list_erc20": "지갑 목록 - ERC20",
    "estimate_rate": "예상 비율: {{rate}}",
  },
  "table": {
    "wallet": "지갑",
    "date": "날짜",
    "status": "상태",
    "amount": "양",
    "admin_fee": "관리 수수료",
    "net_amount": "순액",
    "from_wallet": "지갑에서",
    "to_wallet": "월레에게",
    "amount_musd": "금액(MUSD)",
    "airdrop": "공중 투하",
    "type": "유형",
    "walletCode": "지갑 코드",
    "showing_rows_of": "총 {{totalRow}}개 중 {{currentRow}} 개 행 표시",
    "showing_rows": "행 표시",
    "actions": "작동하다",
  },
  "status": {
    "COMPLETED": "완전한",
    "PENDING": "보류 중",
    "FAILED": "실패한",
    "UNKNOWN": "불특정",
  },
  "sales_package": {
    "FIRST": "First Package",
    "TOP_UP": "Top Up Package"
  },
  "package": {
  "ACTIVATED": "패키지 활성화됨",
  "NO_PACKAGE": "패키지 없음",
  },
};

export default koKR;
