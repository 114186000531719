// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { VERSION } from './constants';
import SnackBar from './components/Snackbar';
import LoadingBackdrop from './components/LoadingBackdrop';

// ----------------------------------------------------------------------

export default function App() {
  const versionInfos = Object.entries(VERSION).map(([key, value]) => `${key}: ${value}`);
  console.log(`%c${versionInfos.join(" | ")} `, 'background: #234b8c; color: white; display: block; font-size: large; padding: 10px')
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <SnackBar />
        <ProgressBarStyle />
        <ScrollToTop />
        <Router />
        <LoadingBackdrop />
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
