import { enUS as muiEnUS, zhCN as muiZhCN, koKR as muiKR, jaJP as muiJP ,Localization } from '@mui/material/locale';
// import {

// } from 'apexcharts/dist/locales';
import { Direction } from '@mui/material/styles';

// yup
import { ILangYup } from './yup/yup.interface';
import enUS, { ILang } from 'src/locales/main/en_us';
import yupEnUS from 'src/locales/yup/en_us';
import yupZhCN from 'src/locales/yup/zh_cn';
import yupKoKR from 'src/locales/yup/ko_kr';
import yupJaJP from 'src/locales/yup/ja_jp';
import zhCN from 'src/locales/main/zh_cn';
import koKR from 'src/locales/main/ko_kr';
import jaJP from 'src/locales/main/ja_jp';

// apex-chart
import acEnUS from 'apexcharts/dist/locales/en.json';
import acZhCN from 'apexcharts/dist/locales/zh-cn.json';
import acKR from 'apexcharts/dist/locales/ko.json';
import acJP from 'apexcharts/dist/locales/ja.json';

// moment
import 'moment/locale/zh-cn';
import 'moment/locale/ko';
import 'moment/locale/ja';

// dataGrid
import { enUS as dataGridEnUS, zhCN as dataGridZhCN, koKR as dataGridKR, jaJP as dataGridJP } from '@mui/x-data-grid';
import { Localization as dataGridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';

// import IconAr from 'src/assets/img/flags/ar.svg';
// import IconEn from 'src/assets/img/flags/en.svg';

// ----------------------------------------------------------------------
export interface LangData {
  label: string;
  labelShort: string;
  value: string;
  muiLang: Localization;
  dataGridLang: dataGridLocalization;
  lang: ILang;
  icon: string;
  iconCircle: string;
  direction: Direction;
  libs: {
    yup: {
      lang: ILangYup;
    },
    apexChart: {
      lang: typeof acEnUS;
      value: string;
    },
    moment: {
      // lang?: any,
      value: string
    }
  },
  // apexChartLang: typeof acEnUS;
  // apexChartValue: string;
};

export const LANGS: LangData[] = [
  {
    label: 'English',
    labelShort: 'ENG',
    value: 'en',
    muiLang: muiEnUS,
    dataGridLang: dataGridEnUS,
    lang: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
    iconCircle: '/assets/icons/flags/ic_flag_us_circle.svg',
    direction: 'ltr',
    libs: {
      yup: {
        lang: yupEnUS,
      },
      apexChart: {
        lang: acEnUS,
        value: 'en',
      },
      moment: {
        value: 'en',
      },
    },
  },
  {
    label: '简体中文',
    labelShort: '简中',
    value: 'cn',
    muiLang: muiZhCN,
    dataGridLang: dataGridZhCN,
    lang: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
    iconCircle: '/assets/icons/flags/ic_flag_cn_circle.svg',
    direction: 'ltr',
    libs: {
      yup: {
        lang: yupZhCN,
      },
      apexChart: {
        lang: acZhCN,
        value: 'zh-cn',
      },
      moment: {
        value: 'zh-cn',
      },
    },
  },
  {
    label: '日本語',
    labelShort: '日本語',
    value: 'ja',
    muiLang: muiJP,
    dataGridLang: dataGridJP,
    lang: jaJP,
    icon: '/assets/icons/flags/ic_flag_jp.svg',
    iconCircle: '/assets/icons/flags/ic_flag_jp_circle.svg',
    direction: 'ltr',
    libs: {
      yup: {
        lang: yupJaJP,
      },
      apexChart: {
        lang: acJP,
        value: 'ja-jp',
      },
      moment: {
        value: 'ja-jp',
      },
    },
  },
  {
    label: '한국어',
    labelShort: '한국어',
    value: 'ko',
    muiLang: muiKR,
    dataGridLang: dataGridKR,
    lang: koKR,
    icon: '/assets/icons/flags/ic_flag_kr.svg',
    iconCircle: '/assets/icons/flags/ic_flag_kr_circle.svg',
    direction: 'ltr',
    libs: {
      yup: {
        lang: yupKoKR,
      },
      apexChart: {
        lang: acKR,
        value: 'ko-kr',
      },
      moment: {
        value: 'ko-kr',
      },
    },
  },
  // {
  //   label: 'عربى',
  //   value: 'ar',
  //   muiLang: muiArSD,
  //   lang: arSD,
  //   yupLang: yupEnUS,
  //   icon: IconAr,
  //   direction: 'rtl',
  // },
  // {
  //   label: 'German',
  //   value: 'de',
  //   muiLang: deDE,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_de.svg',
  //   direction: 'rtl',
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   muiLang: frFR,
  //   icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_flag_fr.svg',
  //   direction: 'ltr',
  // },
];

export const DEFAULT_LANG_DATA = LANGS[0];
export const DEFAULT_LANG = DEFAULT_LANG_DATA.value;

export default LANGS;
