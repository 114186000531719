// @mui
import { Box } from '@mui/material';
import MenuToggle from 'src/components/MenuToggle';
// components
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

type Props = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

export default function CollapseButton({ onToggleCollapse, collapseClick }: Props) {
  return (
    <IconButtonAnimate onClick={onToggleCollapse}>
      <Box
        sx={{
          display:'flex'
          // lineHeight: 0,
          // transition: (theme) =>
          //   theme.transitions.create('transform', {
          //     duration: theme.transitions.duration.shorter,
          //   }),
          // ...(collapseClick && {
          //   transform: 'rotate(180deg)',
          // }),
        }}
      >
        <MenuToggle />
      </Box>
    </IconButtonAnimate>
  );
}

// ----------------------------------------------------------------------
