// components
import { PATH_DASHBOARD } from 'src/routes/paths';
import * as IconsaxReact from 'iconsax-react';

import { NavSectionProps, NavListProps } from 'src/components/nav-section/type';

// ----------------------------------------------------------------------
interface MenuItem {
  title: string;
  path: string;
  icon?: {
    name: string;
    color?: string;
    variant?: IconsaxReact.IconProps['variant']
  },
  children?: MenuItem[];
};

interface MenuData {
  subheader?: string;
  items: MenuItem[];
}

const menuData: MenuData[] = [
  {
    subheader: 'Home',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.index,
        icon: {
          name: 'Category',
        }
      },
    ],
  },
];

const navConfig: NavSectionProps['navConfig'] = [];
menuData.forEach((data) => {
  const navList: NavListProps[] = [];
  data.items.forEach((item) => {
    const { icon, ...others } = item;
    const list: NavListProps = {
      ...others,
    };
    if (icon) {
      const MyIcon: IconsaxReact.Icon = IconsaxReact[icon.name as keyof typeof IconsaxReact];
      // let MyVariant: IconsaxReact.IconProps;
      if (MyIcon !== undefined) {
        list.icon = <MyIcon size="32" color={icon.color || 'currentColor'} variant={icon.variant || 'Bulk'} />;
      }
    }
    navList.push(list);
  });

  navConfig.push({
    subheader: data.subheader || '',
    items: navList,
  });
});

export default navConfig;
