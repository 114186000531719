// @mui
import { alpha } from '@mui/material/styles';
import {
  Link,
  Stack,
  Tooltip,
  IconButton,
  ButtonProps,
  IconButtonProps,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EXTERNAL_URL } from 'src/constants';
//


// ----------------------------------------------------------------------

export type SocialLinks = {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
};

type IProps = IconButtonProps & ButtonProps;

interface Props extends IProps {
  simple?: boolean;
  links?: SocialLinks;
}

export default function SocialsButton({
  simple = true,
  links = {},
  sx,
  ...other
}: Props) {
  const SOCIALS = [
    {
      name: 'FaceBook',
      icon: 'bxl:facebook',
      socialColor: '#787A80',
      path: EXTERNAL_URL.FACEBOOK || '#facebook-link',
    },
    {
      name: 'Twitter',
      icon: 'bxl:twitter',
      socialColor: '#787A80',
      path: EXTERNAL_URL.TWITTER || '#twitter-link',
    },
    {
      name: 'Discord',
      icon: 'bxl:discord-alt',
      socialColor: '#787A80',
      path: EXTERNAL_URL.DISCORD || '#twitter-link',
    },
  ];

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center">
      {SOCIALS.map((social) => {
        const { name, icon, path, socialColor } = social;
        return <Link key={name} href={path} target="_blank">
          <Tooltip title={name} placement="top">
            <IconButton
              sx={{
                color: socialColor,
                '&:hover': {
                  bgcolor: alpha(socialColor, 0.08),
                },
                ...sx,
              }}
              {...other}
            >
              <Iconify icon={icon} sx={{ width: 20, height: 20 }} />
            </IconButton>
          </Tooltip>
        </Link>
      })}
    </Stack>
  );
}
