// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { Property } from 'csstype';
// components
import SocialsButton from './SocialsButton';
import Copyright from './copyright';
import useLocales from 'src/hooks/useLocales';
import { PATH_PAGE } from 'src/routes/paths';
import useSettings from 'src/hooks/useSettings';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  const isDesktop = useResponsive('up', 'sm');
  return {
    position: isDesktop ? 'fixed' : 'relative',
    bottom: isDesktop? '0' : 'unset',
    width: '100%',
    backgroundColor: theme.palette.background.default,
  }
});

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { themeStretch } = useSettings();
  const { translate } = useLocales();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useResponsive('up', 'sm');
  const links = [
    {
      name: translate('page_title.term_conditions'),
      href: PATH_PAGE.terms_conditions
    },
    {
      name: translate('page_title.privacy_policy'),
      href: PATH_PAGE.privacy_policy
    },
  ];
  const dividerColor = isLight ? theme.palette.grey[100] : theme.palette.grey[500];
  const justify: Property.JustifyContent = isDesktop ? 'start' : 'center';
  const textCenter: Property.TextAlign = isDesktop ? 'start' : 'center';
  const textSize = useResponsive('up', 'md') ? theme.typography.body1 : theme.typography.body2;
  return (
    <RootStyle>
      <Divider sx={{ borderColor: dividerColor }}/>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ py: 1 }}>
        {
          isDesktop ?
            <Stack direction='row' justifyContent='space-between'>
              <Stack
                direction='row'
                spacing={{ xs: 1, lg: 2 }}
                divider={<Divider orientation="vertical" sx={{ height: '1rem', borderColor: dividerColor }} />}
                alignItems='center'
              >
                <Copyright sx={{ fontSize: textSize }} />
                {
                  links.map((element, index) => (
                    <Link
                      key={`footerLink_` + index}
                      href={element.href}
                      target="_blank"
                      underline="hover"
                      color="text.primary"
                    >
                      {element.name}
                    </Link>
                  ))
                }
              </Stack>
              <Stack direction='row' spacing={{ xs: 1, lg: 2 }} alignItems='center'>
                <Typography sx={{ fontSize: textSize }}>{translate('footer.follow_us')}</Typography>
                <SocialsButton />
              </Stack>
            </Stack>
            :
            <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems='center' textAlign={textCenter} >
                  <Grid item xs={12}>
                    <Copyright />
                  </Grid>
                  <Grid item xs={12} justifyContent="space-around" display="flex">
                    {
                      links.map((element, index) => (
                        <Link
                          key={`mobilefooter_` + index}
                          href={element.href}
                          target="_blank"
                          underline="hover"
                          color="text.primary"
                        >
                          {element.name}
                        </Link>
                      ))
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction='row' spacing={{ xs: 1, lg: 2 }} alignItems='center' justifyContent={justify}>
                  <Typography>{translate('footer.follow_us')}</Typography>
                  <SocialsButton />
                </Stack>
              </Grid>
            </Grid>
        }
      </Container>
    </RootStyle>
  );
}
