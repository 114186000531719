import { ILang } from './en_us';

export const zhCN: ILang = {
  "header": {
    "mode": {
      "light": "浅色",
      "dark": "深色",
    },
    "language": "语言",
    "dark_theme": "深色模式",
    "setting_list": {
      "my_referral_list": "我的推荐名单",
      "sponsor_setting": "赞助设置",
      "transaction_history": "交易记录",
      "my_network": "我的网络",
      "announcement": "公告",
    },
  },
  "form": {
    "label": {
      "referrals_address": "推荐人地址",
      "search": "搜索",
      "start_date": "开始日期",
      "end_date": "结束日期",
    }
  },
  "connect": {
    "connect_wallet": "连接钱包",
  },
  "error": {
    "went_wrong": 'Opps，无法连接',
    "check_internet": '请检查您的互联网连接或稍后再试',
    "insufficient_balance": "余额不足",
  },
  "notification_message": {
    "on_create": "{{title}}已成功添加!",
    "on_edit": "{{title}}已成功更改!",
    "on_delete": "{{title}}已成功删除!",
    "on_copied": "{{title}}已成功复制!",
  },
  "page_title": {
    "home": "主页",
    "announcement": "公告",
    "transaction_history": "交易记录",
    "term_conditions": "条款和条件",
    "privacy_policy": "隐私政策",
  },
  "my_referral_list": {
    "referral_link": "推荐链接",
  },
  "my_network": {
    "referees": "Referees",
    "refer_to_friend": "推荐您的朋友并获得一些奖金！",
    "your_gp_sales": "您的团体销售",
    "your_referees_gp_sales": "您的推荐人的团体销售",
  },
  "footer": {
    "copyright": "©{{year}} {{appname}}。版权所有",
    "follow_us": "关注我们",
  },
  "common": {
    "total_mining_power": "总算力",
    "personal_mining_power": "个人算力",
    "single_token_package": "单币套餐",
    "multiple_token_package": "多币套餐",
    "mine_mstake": "挖MSTAKE",
    "transfer": "转移",
    "history": "历史",
    "purchase": "购买",
    "yesterday": "昨天",
    "today": "今天",
    "view_history": "查看历史",
    "manage_wallet": "管理钱包",
    "withdraw": "提取",
    "metastake_wallet": "MetaStake钱包",
    "deposit": "订金",
    "withdrawal": "退出",
    "staking_bonus": "质押红利",
    "airdrop": "空投",
    "sales": "销售量",
    "mining_bonus": "挖矿奖励",
    "copy_link": "复制链接",
    "share_qr": "分享二维码",
    "save": "保存",
    "review": "查看",
    "reset": "重置",
    "filter": "筛选",
    "confirm": "确认",
    "balance": "余额",
    "total_purchase": "购买总额",
    "back": "后退",
    "next": "下一个",
    "lock": "锁定",
    "locked_rate": "锁定利率",
    "purchase_successful": "购买成功！",
    "select_wallet": "选择钱包",
    "purchase_amount": "购买金额",
    "search_name": "搜索名称",
    "close": "关闭",
    "bonus": "奖金",
    "address": "地址",
    "receiver_address": "收件人地址",
    "add_wallet": "添加钱包",
  },
  "home": {
    "min_purchase_caption": "最低购买金额为 {{value}} 美元",
    "estimated_lock_rate": "预计锁定率（美元）",
    "estimated_lock_rate_desc": "请在继续购买套餐之前锁定价格",
    "lock_time_remaining": "剩余锁定时间",
    "transaction_num": "交易号：{{value}}",
    "lock_timeout_msg": "锁定价格超时。 请重新锁定价格",
    "primary_token_min_percent_msg": "*{{token}} wallet MIN {{value}}% use for payment",
    "secondary_token_min_percent_msg": "*{{token}} wallet MAX {{value}}% use for payment",
    "wallet_list_erc20": "钱包列表 - ERC20",
    "estimate_rate": "Estimate Rate: {{rate}}",
  },
  "table": {
    "wallet": "钱包",
    "date": "日期",
    "status": "地位",
    "amount": "数量",
    "admin_fee": "管理费",
    "net_amount": "净额",
    "from_wallet": "从钱包",
    "to_wallet": "到钱包",
    "amount_musd": "金额 (MUSD)",
    "airdrop": "空投",
    "type": "类型",
    "walletCode": "钱包代码",
    "showing_rows_of": "显示 {{currentRow}} 行，共 {{totalRow}} 行",
    "showing_rows": "显示行",
    "actions": "操作",
  },
  "status": {
    "COMPLETED": "完全",
    "PENDING": "待办",
    "FAILED": "失败",
    "UNKNOWN": "未知",
  },
  "sales_package": {
    "FIRST": "First Package",
    "TOP_UP": "Top Up Package"
  },
  "package": {
    "ACTIVATED": "配套已激活",
    "NO_PACKAGE": "无配套",
  },
};

export default zhCN;