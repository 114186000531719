import { ILang } from './en_us';

export const jaJP: ILang = {
  "header": {
    "mode": {
      "light": "光",
      "dark": "暗い",
    },
    "language": "言語",
    "dark_theme": "ダークテーマ",
    "setting_list": {
      "my_referral_list": "私の紹介リスト",
      "sponsor_setting": "スポンサー設定",
      "transaction_history": "取引履歴",
      "my_network": "私のネットワーク",
      "announcement": "発表",
    },
  },
  "form": {
    "label": {
      "referrals_address": "紹介者の住所",
      "search": "探す",
      "start_date": "開始日",
      "end_date": "終了日",
    }
  },
  "connect": {
    "connect_wallet": "コネクトウォレット",
  },
  "error": {
    "went_wrong": "Opps、接続に失敗しました",
    "check_internet": "インターネット接続を確認するか、後でもう一度やり直してください",
    "insufficient_balance": "残高不足",
  },
  "notification_message": {
    "on_create": "{{title}}正常に追加されました！",
    "on_edit": "{{title}}正常に編集されました！",
    "on_delete": "{{title}}正常に削除されました！",
    "on_copied": "{{title}}正常にコピーされました！",
  },
  "page_title": {
    "home": "ホームページ",
    "announcement": "発表",
    "transaction_history": "取引履歴",
    "term_conditions": "利用規約",
    "privacy_policy": "プライバシーポリシー",
  },
  "my_referral_list": {
    "referral_link": "紹介リンク",
  },
  "my_network": {
    "referees": "Referees",
    "refer_to_friend": "友達を紹介してボーナスを獲得しましょう！",
    "your_gp_sales": "あなたのグループセールス",
    "your_referees_gp_sales": "あなたの審判のグループ販売",
  },
  "footer": {
    "copyright": "©{{year}} {{appname}}。全著作権所有",
    "follow_us": "フォローする",
  },
  "common": {
    "total_mining_power": "総採掘力",
    "personal_mining_power": "個人の採掘力",
    "single_token_package": "単一通貨パッケージ",
    "multiple_token_package": "マルチ通貨パッケージ",
    "mine_mstake": "MSTAKEを掘る",
    "transfer": "移行",
    "history": "歴史",
    "purchase": "買う",
    "yesterday": "昨日",
    "today": "今日",
    "view_history": "履歴を表示",
    "manage_wallet": "ウォレットを管理する",
    "withdraw": "エキス",
    "metastake_wallet": "MetaStakeウォレット",
    "deposit": "デポジット",
    "withdrawal": "撤退",
    "staking_bonus": "ステーキングボーナス",
    "airdrop": "AirDrop",
    "sales": "売上高",
    "mining_bonus": "マイニングボーナス",
    "copy_link": "コピーリンク",
    "share_qr": "QRを共有",
    "save": "保存",
    "review": "レビュー",
    "reset": "リセット",
    "filter": "フィルター",
    "confirm": "確認",
    "balance": "残高",
    "total_purchase": "総購入",
    "back": "戻る",
    "next": "次",
    "lock": "ロック",
    "locked_rate": "ロックイン率",
    "purchase_successful": "購入に成功しました！",
    "select_wallet": "ウォレットを選択",
    "purchase_amount": "購入価格",
    "search_name": "検索名",
    "close": "閉鎖",
    "bonus": "ボーナス",
    "address": "住所",
    "receiver_address": "受信者のアドレス",
    "add_wallet": "ウォレットを追加する",
  },
  "home": {
    "min_purchase_caption": "最小購入額は{{value}}です",
    "estimated_lock_rate": "推定ロックイン率（USD）",
    "estimated_lock_rate_desc": "プランを進める前に価格を固定してください",
    "lock_time_remaining": "残りのロック時間",
    "transaction_num": "トランザクション番号：{{value}}",
    "lock_timeout_msg": "ロックされた価格がタイムアウトしました。 価格を再度ロックしてください",
    "primary_token_min_percent_msg": "*{{token}} wallet MIN {{value}}% use for payment",
    "secondary_token_min_percent_msg": "*{{token}} wallet MAX {{value}}% use for payment",
    "wallet_list_erc20": "財布リスト - ERC20",
    "estimate_rate": "推定レート: {{rate}}",
  },
  "table": {
    "wallet": "財布",
    "date": "日にち",
    "status": "状態",
    "amount": "額",
    "admin_fee": "管理費",
    "net_amount": "正味金額",
    "from_wallet": "ウォレットから",
    "to_wallet": "ウォールへt",
    "amount_musd": "金額 (MUSD)",
    "airdrop": "エアドロップ",
    "type": "タイプ",
    "walletCode": "ウォレットコード",
    "showing_rows_of": "合計{{totalRow}}行の{{currentRow}}行を表示",
    "showing_rows": "行を表示",
    "actions": "操作する",
  },
  "status": {
    "COMPLETED": "完成",
    "PENDING": "保留中",
    "FAILED": "失敗した",
    "UNKNOWN": "未定義",
  },
  "sales_package": {
    "FIRST": "First Package",
    "TOP_UP": "Top Up Package"
  },
  "package": {
    "ACTIVATED": "パッケージアクティベート",
    "NO_PACKAGE": "パッケージなし",
  },
};

export default jaJP;
