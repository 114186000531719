import DOMPurify from "dompurify";

export function fMiddleEllipsis(str: string, prefixLength = 5, postfixLength = 4) {
    let prefix = str.slice(0, prefixLength);
    let postfix = str.slice(-postfixLength);
    let number = prefix + '...' + postfix

    return number;
}

export function fHtmlContent(str: string) {
    const clean = DOMPurify.sanitize(str);
    return clean
}