import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import ConnectWallet from "src/sections/auth/ConnectWallet";
import Image from "src/components/Image";
import { fMiddleEllipsis } from "src/utils/formatString";
import { LoadingButton } from "@mui/lab";
import LoadingOverlay from "src/components/LoadingOverlay";

export default function ConnectWallerPopover() {
  const { user } = useAuth();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (!user) {
    return (<></>);
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{ textTransform: 'unset' }}
        onClick={handleOpen}
        size={isDesktop ? 'medium' : 'small'}
      >
        {user.address ? <Stack direction="row" spacing={1} alignItems="center">
          <Image src="/assets/icons/navbar/ic_wallet.svg" sx={{
            width: '20px',
            height: '20px',
          }} />
            <Typography sx={{
              // textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
              // overflow: 'hidden',
              maxWidth: '100px'
            }}>{fMiddleEllipsis(user.address)}</Typography>
        </Stack> : translate('connect.connect_wallet')}
      </Button>
      <Dialog
        onClose={handleClose}
        open={Boolean(open)}
        fullWidth
        maxWidth='sm'
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            zIndex: '1',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'eva:close-fill'} color="text.primary" width={32} height={32} />
        </IconButton>
        <ConnectWallet closeModal={handleClose} setLoadingBtn={setLoadingBtn} />
      </Dialog>
    </>
  );
}