import { Backdrop, Card, CircularProgress } from '@mui/material';
import { useSelector } from 'src/redux/store';

export default function LoadingBackdrop() {
  const { loading } = useSelector((state) => state.loading);

  return <Backdrop
    sx={{ zIndex: 1500 }}
    open={loading}
  >
    <Card>
      <CircularProgress color="primary" />
    </Card>
  </Backdrop>
}