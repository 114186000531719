/* eslint-disable no-template-curly-in-string */
import {
  // mixed,
  // string,
  // number,
  // date,
  // boolean,
  // object,
  // array,
} from 'yup';
import notType from './notType';
import { ILangYup } from './yup.interface';

export const jaJP: ILangYup = {
  mixed: {
    default: '無効な${path}',
    required: '${path}が必要です',
    defined: '${path}を定義する必要があります',
    notNull: '${path}を空にすることはできません',
    oneOf: '${path}は、次のいずれかの値である必要があります：${values}',
    notOneOf: '${path}は、次のいずれかの値にすることはできません：${values}',
    notType: (params) => notType(params, jaJP, {
      cast: ' （値${value}から変換）。',
      mixed: '${path}は構成のタイプと一致する必要があります。検証値は次のとおりです：${value}',
      not_mixed: '${path}は${value}である必要があります',
      unknown_type: 'Unknown',
      full_stop: '。',
    }),
  },
  string: {
    length: '${path}は正確に${length}文字である必要があります',
    min: '${path}には少なくとも${min}文字が必要です',
    max: '${path}は最大${max}文字しか含めることができません',
    matches: '${path}は次のものと一致する必要があります： "${regex}"',
    email: '${path}は有効なメールアドレスである必要があります',
    url: '${path}は有効なURLである必要があります',
    uuid: '${path}は有効なUUIDである必要があります',
    trim: '${path}はトリミングされた文字列である必要があります',
    lowercase: '${path}は小文字の文字列である必要があります',
    uppercase: '${path}は大文字でなければなりません',
  },
  number: {
    min: '${path}は${min}以上である必要があります',
    max: '${path}は${max}以下である必要があります',
    lessThan: '${path}は${less}未満である必要があります',
    moreThan: '${path}は${more}より大きくなければなりません',
    positive: '${path}は正でなければなりません',
    negative: '${path}は負である必要があります',
    integer: '${path}は整数でなければなりません',
  },
  date: {
    min: '${path}フィールドは${min}より後でなければなりません',
    max: '${path}フィールドは${max}より古い必要があります',
  },
  boolean: {
    isValue: '${path}フィールドは${value}である必要があります',
  },
  object: {
    noUnknown: '${path}フィールドに未指定のキーがあります：${unknown}',
  },
  array: {
    min: '${path}フィールドには少なくとも${min}アイテムが必要です',
    max: '${path}フィールドは${max}アイテム以下である必要があります',
    length: '${path}には${length}アイテムが必要です',
  },
  _additional: {
    _type: {
      ref: 'ref',
      mixed: 'ミックス',
      boolean: 'ブール値',
      string: 'ストリング',
      number: '番号',
      date: '日にち',
      object: '目的',
      array: '配列',
      tuple: 'タプル',
      lazy: 'lazy',
    },
  },
};

export default jaJP;
