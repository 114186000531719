import React, { ReactNode, useEffect, useState } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';
// component
import { YupSchema, YupSpecs } from './YupSpecs';
import { useTranslation } from 'react-i18next';
import { catchError, throwError, finalize, Subscription } from 'rxjs';
import LoadingOverlay from '../LoadingOverlay';
import axios from 'src/utils/axios';
import LoadReload from '../LoadReload';

// ----------------------------------------------------------------------
interface ContextValue {
  yupSpecs?: YupSpecs;
  formData?: any;
  readOnly: boolean;
}

export interface Props {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  yupSchema?: YupSchema;
  autoComplete?: string;
  readOnly?: boolean;
  editUrl?: string;
  formId?: string;
};

export const FormContext = React.createContext<ContextValue>({ readOnly: false });
export const FormContextProvider = FormContext.Provider;

export default function FormProvider({
  children,
  onSubmit,
  methods,
  yupSchema,
  autoComplete,
  readOnly = false,
  editUrl: url,
  formId,
}: Props) {

  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(false);
  const [dataSubs, setDataSubs] = useState<Subscription>();

  let formData: any = "";

  let yupSpecs: YupSpecs | undefined;
  if (yupSchema) {
    yupSpecs = new YupSpecs(yupSchema);
  }

  const onLangChange = (lang: string) => {
    const errs = methods.formState.errors;
    if (errs) {
      const errList = Object.keys(errs);
      methods.trigger(errList);
    }
    // methods.trigger();
  }

  useEffect(() => {
    i18n.on('languageChanged', onLangChange);

    return () => {
      i18n.off('languageChanged', onLangChange);
    }
  }, [methods.formState.errors]);

  const getData = () => {
    if (dataSubs) {
      dataSubs.unsubscribe();
    }
    if (url) {
      setLoading(true)
      const currentSub = axios.get(url, {
          params: { formId },
        })
        .pipe(catchError((err) => {
          setRetry(true);
          return throwError(() => err);
        }))
        .pipe(finalize(() => setLoading(false)))
        .subscribe((res) => {
          formData = res.data.data;
          for (const x of Object.keys(formData)) {
            methods.setValue(x, formData[x])
          }
        });
      setDataSubs(currentSub);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <LoadingOverlay isShow={loading}>
      <Form {...methods}>
        <FormContextProvider value={{ yupSpecs, formData, readOnly }} >
          {retry ?
            <LoadReload data={formData} retryState={[retry, setRetry]} init={getData} />
            :
            <form autoComplete={autoComplete} onSubmit={onSubmit} noValidate={true}>
              {children}
            </form>
          }
        </FormContextProvider>
      </Form>
    </LoadingOverlay>
  );
}
