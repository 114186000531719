import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SwitchStyle } from './style';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import { Button } from '@mui/material';

export function ModeSwitch() {
  const { translate } = useLocales();
  const { themeMode, onChangeMode } = useSettings();

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChangeMode(checked ? 'dark' : 'light');
  const onClick = () => onChangeMode(checked ? 'light' : 'dark');
  const checked = themeMode === 'dark';

  return (
    // <Stack
    //   direction="row"
    //   spacing={1}
    //   alignItems="center"
    //   justifyContent="space-between" sx={{ my: 1 }}
    // >
    //   <Typography sx={{ color: 'text.primary' }}>{ translate('header.dark_theme') }</Typography>
    //   <SwitchStyle checked={checked} onChange={onChange} />
    // </Stack>

    <Button variant='text'
      sx={{
        textTransform: 'unset',
        color: 'text.primary',
        justifyContent: 'space-between',
        '&:hover': {
          backgroundColor: 'unset',
        },
        pl: 0,
        pr: 1
      }}
      onClick={onClick}
      fullWidth
      endIcon={
        <SwitchStyle checked={checked} onChange={onChange} />
      }
      disableRipple
      // onClick={onChange}
    >
      <Typography sx={{ color: 'text.primary' }}>{translate('header.dark_theme')}</Typography>
    </Button>
  );
}