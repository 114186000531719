import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
import { RegisterState } from 'src/@types/register';
import _ from 'lodash';

// ----------------------------------------------------------------------

type FormData = RegisterState['formData'];
type ParentKey = keyof FormData;
interface FormDataPayloadBase {
  parentKey: ParentKey;
  data: FormData[ParentKey];
}

interface FormDataPayloadStep1 extends FormDataPayloadBase {
  parentKey: 'step1';
  data: FormData['step1'];
}

interface FormDataPayloadStep2 extends FormDataPayloadBase {
  parentKey: 'step2';
  data: FormData['step2'];
}

type FormDataPayload = FormDataPayloadStep1 | FormDataPayloadStep2;

const initialState: RegisterState = {
  formData: {
    step1: {
      first_name: "",
      middle_name: "",
      last_name: "",
      country_code: "",
      contact_number: "",
      email: "",
      lang_id: "",
    },
    step2: {
      account_setting: {
        lang_id: "",
        preferred_currency_id: "",
        account_class: "",
        password: "",
        retype_password: "",
      },
      personal_particular: {
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        dob: "",
        gender: "",
        citizenship: "",
        country_of_residence: "",
        id_type: "",
        id_information: "",
        personal_address: "",
        city: "",
        country: "",
        postal_code: "",
      },
      contact_information: {
        country_code: "",
        phone_number: "",
        email: "",
        referrer: "",
      },
    },
    campaign: {
      campaign_id: "",
      web_source: "",
      web_medium: "",
      web_term: "",
      utm_campaign: "",
    }
  },
};

const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    updateFormDataStep1(state, action: PayloadAction<RegisterState['formData']['step1']>) {
      const data = action.payload;
      state.formData.step1 = {
        ...data,
      };
    },

    updateFormDataStep2Acc(state, action: PayloadAction<RegisterState['formData']['step2']['account_setting']>) {
      const data = action.payload;
      state.formData.step2.account_setting = {
        ...data,
      };
    },

    updateFormDataStep2Personal(state, action: PayloadAction<RegisterState['formData']['step2']['personal_particular']>) {
      const data = action.payload;
      state.formData.step2.personal_particular = {
        ...data,
      };
    },

    updateFormDataStep2Contact(state, action: PayloadAction<RegisterState['formData']['step2']['contact_information']>) {
      const data = action.payload;
      state.formData.step2.contact_information = {
        ...data,
      };
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  updateFormDataStep1,
  updateFormDataStep2Acc,
  updateFormDataStep2Personal,
  updateFormDataStep2Contact,
} = slice.actions
