export enum SALES_PACKAGE {
    FIRST = 1,
    TOP_UP = 2
}

export enum PACKAGE {
    ACTIVATED = 1,
    NO_PACKAGE = 2
}

export const PACKAGE_COLOR = {
    [PACKAGE.ACTIVATED]: 'success.main',
    [PACKAGE.NO_PACKAGE]: 'error.main',
}