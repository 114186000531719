import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // backgroundColor: theme.palette.grey[isLight ? 800 : 700]
          backgroundColor: isLight ? theme.palette.background.default : theme.palette.text.secondary,
          color: 'currentColor',
          boxShadow: theme.shadows[5],
          border: isLight ? '1px solid rgb(145 158 171 / 20%)' : '0px',
          fontSize: '1rem',
        },
        arrow: {
          // color: theme.palette.grey[isLight ? 800 : 700]
          color: isLight ? theme.palette.background.default : theme.palette.text.secondary,
          "&::before": {
            border: isLight ? '1px solid rgb(145 158 171 / 20%)' : '0px',
            backgroundColor: isLight ? theme.palette.background.default : theme.palette.text.secondary,
            boxSizing: "border-box"
          }
        }
      }
    }
  };
}
