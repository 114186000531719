/* eslint-disable no-template-curly-in-string */
import {
  // mixed,
  // string,
  // number,
  // date,
  // boolean,
  // object,
  // array,
} from 'yup';
import notType from './notType';
import { ILangYup } from './yup.interface';

export const zhCN: ILangYup = {
  mixed: {
    default: '无效的${path}',
    required: '${path}是必填项',
    defined: '${path}必须定义',
    notNull: '${path}不能为空',
    oneOf: '${path}必须是以下值之一：${values}',
    notOneOf: '${path}不能是以下值之一：${values}',
    notType: (params) => notType(params, zhCN, {
      cast: ' (从值${value}转换)。',
      mixed: '${path}必须与配置的类型匹配。验证值为：${value}',
      not_mixed: '${path}必须是${value}',
      unknown_type: 'Unknown',
      full_stop: '。',
    }),
  },
  string: {
    length: '${path}必须正好是${length}个字符',
    min: '${path}必须至少有${min}个字符',
    max: '${path}最多只能有${max}个字符',
    matches: '${path}必须匹配以下内容："${regex}"',
    email: '${path}必须是有效的电子邮件',
    url: '${path}必须是有效的 URL',
    uuid: '${path}必须是有效的 UUID',
    trim: '${path}必须是修剪后的字符串',
    lowercase: '${path}必须是小写字符串',
    uppercase: '${path}必须是大写字符串',
  },
  number: {
    min: '${path}必须大于或等于${min}',
    max: '${path}必须小于或等于${max}',
    lessThan: '${path}必须小于${less}',
    moreThan: '${path}必须大于${more}',
    positive: '${path}必须是正数',
    negative: '${path}必须是负数',
    integer: '${path}必须是整数',
  },
  date: {
    min: '${path}字段必须晚于${min}',
    max: '${path}字段必须早于${max}',
  },
  boolean: {
    isValue: '${path}字段必须是${value}',
  },
  object: {
    noUnknown: '${path}字段有未指定的键：${unknown}',
  },
  array: {
    min: '${path}字段必须至少有${min}个项目',
    max: '${path}字段必须少于或等于${max}个项目',
    length: '${path}必须有${length}个项目',
  },
  _additional: {
    _type: {
      ref: 'ref',
      mixed: '混合',
      boolean: '布尔值',
      string: '字符串',
      number: '数字',
      date: '日期',
      object: '目的',
      array: '数组',
      tuple: '元组',
      lazy: 'lazy',
    },
  },
};

export default zhCN;
