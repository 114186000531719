export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const API_PREFIX = HOST_API + '/api';
export const API_LIST = {
  AUTH: {
    LOGIN: '/account/login',
  },
  HOME: {
    INDEX: '/home/index',
    WALLET_LIST: '/home/wallet-list',
    GET_SINGLE_TOKEN_DATA: 'home/get-single-token-data',
    GET_MULTIPLE_TOKEN_DATA: 'home/get-multiple-token-data',
    PURCHASE_SINGLE_PACKAGE: 'home/purchase-single-package',
    PURCHASE_MULTIPLE_PACKAGE: 'home/purchase-multiple-package',
    LOCK_RATE: 'home/lock-rate',
    WALLET_LIST_ERC20: 'home/wallet-list-erc20',
    ESTIMATE_RATE: 'home/estimate-rate',
    MANAGE_WALLET: 'home/manage-wallet',
  },
  TRANSACTION_HISTORY: {
    DEPOSIT: '/get-transfer',
    WITHDRAWAL: '/get-withdrawal',
    TRANSFER: '/get-transfer',
    STAKING_BONUS: '/get-staking-bonus',
    AIRDROP: '/get-airdrop',
    SALES: '/get-sales',
    MINING_BONUS: '/get-mining-bonus',
  },
  CONNECT_WALLET_LIST: '/get-connect-wallet-list',
  NETWORK_LIST: '/get-network-list',
  SAVE_REFERRAL: '/save-referal',
  GET_REFEREES: '/get-referess',
  GET_ANNOUNCEMENT: '/get-announcement',
};
