import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import { PATH_PAGE } from "src/routes/paths";

export default function TransactionTab() {
    const navigate = useNavigate();
    const { translate } = useLocales();
    return (
        <>
            <Button
                variant='text'
                sx={{
                    textTransform: 'unset',
                    color: 'text.primary',
                    justifyContent: 'space-between',
                    '&:hover': {
                        backgroundColor: 'unset',
                    },
                    pl: 0,
                    pr: 1
                }}
                fullWidth
                endIcon={
                    <Iconify icon={'eva:chevron-right-outline'} />
                }
                disableRipple
                onClick={() => navigate(PATH_PAGE.transaction_history)}
            >
                <Typography sx={{ color: 'text.primary' }}>{translate('header.setting_list.transaction_history')}</Typography>
            </Button>
        </>
    )
}