// @mui
import { InputAdornment, useTheme } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import CustomSvgIcon from '../CustomSvgIcon';
import Iconify from '../Iconify';
import RHFTextField, { IProps as RHFTextFieldIProps } from './RHFTextField';

// ----------------------------------------------------------------------

interface IProps extends Omit<RHFTextFieldIProps, 'name'> {
  name?: string;
  icon?: boolean;
  placeholder?: string;
}

export default function RHFSearchTextField({
  name = 's',
  icon = false,
  placeholder,
  ...others
}: IProps) {
  const { translate } = useLocales();
  const label = placeholder ? placeholder : translate('form.label.search');
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RHFTextField
      name={name}
      placeholder={label}
      InputProps={icon ? {
        startAdornment: (
          <InputAdornment position="start">
            {/* <Iconify icon={'eva:search-fill'} color={isLight ? theme.palette.grey[500] :"secondary.main"} width={18} height={18} /> */}
            <CustomSvgIcon icon="search" color={isLight ? theme.palette.grey[500] : "secondary.main"} width={18} height={18} />
          </InputAdornment>
        ),
      } : {}}
      {...others}
    />
  )
}
