import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  light: '#EAF3FE',
  main: '#2B87F7',
  dark: '#2065B9',
  darker: '#0F2F56',
  contrastText: '#fff',
};
const SECONDARY = {
  lighter: '#F9FAFB',
  light: '#DFE3E8',
  main: '#919EAB',
  dark: '#454F5B',
  darker: '#161C24',
};
// const SECONDARY = {
//   lighter: '#D6E4FF',
//   light: '#84A9FF',
//   main: '#3366FF',
//   dark: '#1939B7',
//   darker: '#091A7A',
// };
const INFO = {
  lighter: '#D0F2FF',
  light: '#EAF3FE',
  main: '#1890FF',
  dark: '#2065B9',
  darker: '#04297A',
};
const SUCCESS = {
  light: '#EDF9E9',
  main: '#4AC026',
  dark: '#38901D',
  darker: '#1A430D',
};
const WARNING = {
  light: '#FFF9E6',
  main: '#FFC107',
  dark: '#BF9105',
  darker: '#594402',
};
const ERROR = {
  light: '#FFEDEC',
  main: '#FF4842',
  dark: '#FF4842',
  darker: '#591917',
};

const GREY = {
  0: '#FFFFFF',
  50: '#ebebec',
  100: '#c1c2c4',
  200: '#a3a4a8',
  300: '#787a80',
  400: '#5e6168',
  500: '#363942',
  600: '#31343c',
  700: '#26282f',
  800: '#1e1f24',
  900: '#17181c',
  50_25: alpha('#ebebec', 0.25),
  500_8: alpha('#363942', 0.08),
  500_12: alpha('#363942', 0.12),
  500_16: alpha('#363942', 0.16),
  500_24: alpha('#363942', 0.24),
  500_32: alpha('#363942', 0.32),
  500_48: alpha('#363942', 0.48),
  500_56: alpha('#363942', 0.56),
  500_80: alpha('#363942', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[50_25],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: '#28292D', secondary: '#63656D', disabled: '#B9BDCC' },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: '#7E8498', disabled: '#B9BDCC' },
    background: { paper: '#17181D', default: '#17181D', neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
