import { Theme } from '@mui/material/styles';
import { FORM_PROVIDER_READONLY_LABEL } from 'src/constants';

// ----------------------------------------------------------------------

export default function Switch(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          [`&.${FORM_PROVIDER_READONLY_LABEL}`]: {
            '& .Mui-checked.Mui-disabled, & .Mui-disabled': {
              color: '#fff',
              '&.Mui-checked': {
                color: theme.palette.primary.main,
              },
              // [`&.${FORM_PROVIDER_READONLY_LABEL}`]: {
              //   color: theme.palette.text.secondary,
              //   '&.Mui-checked': {
              //     color: theme.palette.primary.main,
              //   },
              // }
            },
            '& .MuiSwitch-track': {
              backgroundColor: `${theme.palette.grey[100]} !important`,
            },
            '& .Mui-checked+.MuiSwitch-track': {
              backgroundColor: `${theme.palette.primary.main} !important`,
              opacity: .5,
            },
          },
        },
        thumb: {
          boxShadow: theme.customShadows.z1
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.grey[500]
        },
        switchBase: {
          left: 0,
          right: 'auto',
          '&:not(:.Mui-checked)': {
            color: theme.palette.grey[isLight ? 100 : 300]
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.grey[isLight ? 400 : 600]
          },
          '&.Mui-disabled+.MuiSwitch-track': {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`
          }
        }
      }
    }
  };
}
