import { Theme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function DataGrid(theme: Theme) {
  const isDesktop = useResponsive('up', 'lg');
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: `16px`,
          border: `1px solid transparent`,
          '& .MuiTablePagination-root': {
            borderTop: 0,
          },
          '& .MuiDataGrid-toolbarContainer': {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.neutral,
            '& .MuiButton-root': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
          },
          '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within, ': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeaders': {
            border: '1px solid',
            borderRadius: '8px 8px 0px 0px',
            borderColor: theme.palette.grey[50],
            fontSize: theme.typography.body1,
          },
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            // padding: '0 16px'
            padding: theme.spacing(2)

          },


          '& .MuiDataGrid-cell': {
            '&:last-of-type': {
              borderRight: '1px solid #ebebec',
            },
            '&:first-of-type': {
              borderLeft: '1px solid #ebebec',
            },
            borderColor: theme.palette.grey[50],
          },

          '& .MuiDataGrid-footerContainer': {
            borderTop: `unset`,
          },

          // homepage table customise styling
          '&.homepage-wallet-list': {
            '& .MuiDataGrid-row': {
              margin: `0 !important`,
            },
            '& .MuiDataGrid-row--lastVisible': {
              '& .MuiDataGrid-cell': {
                borderBottomColor: theme.palette.grey[50] + `!important`,
                '&:last-of-type': {
                  borderRadius: `0px 0px 12px 0px`,
                },
                '&:first-of-type': {
                  borderRadius: `0px 0px 0px 12px`,
                },
              }
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'unset',
            }
          },

          //  transaction history table customise styling
          '&.transaction-history-table': {
            '& .MuiDataGrid-columnHeaders': {
              display: isDesktop ? 'flex' : 'none',
            },
            '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
              padding: theme.spacing(2)
            },
            '& .MuiDataGrid-virtualScroller': {
              marginTop: isDesktop ? '72px' : '10px !important',
            },
            '& .MuiDataGrid-cell': {
              border: !isDesktop ? '1px solid' : '1px 0px solid',
              borderRadius: !isDesktop ? '12px' : 'unset',
              // '&:last-of-type': {
              //   borderRight: '1px solid #ebebec',
              // },
              // '&:first-of-type': {
              //   borderLeft: '1px solid #ebebec',
              // },
              borderColor: theme.palette.grey[50],
            },
            '& .MuiDataGrid-row--lastVisible': {
              '& .MuiDataGrid-cell': {
                borderBottomColor: theme.palette.grey[50] + `!important`,
                '&:last-of-type': {
                  borderRadius: isDesktop ? `0px 0px 12px 0px` : `12px`,
                },
                '&:first-of-type': {
                  borderRadius: isDesktop ? `0px 0px 0px 12px` : `12px`,
                },
              }
            },
          }
        },
      },
    },
    MuiGridMenu: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-gridMenuList': {
            boxShadow: theme.customShadows.z20,
            borderRadius: theme.shape.borderRadius,
          },
          '& .MuiMenuItem-root': {
            ...theme.typography.body2,
          },
        },
      },
    },
    MuiGridFilterForm: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1.5, 0),
          '& .MuiFormControl-root': {
            margin: theme.spacing(0, 0.5),
          },
          '& .MuiInput-root': {
            marginTop: theme.spacing(3),
            '&::before, &::after': {
              display: 'none',
            },
            '& .MuiNativeSelect-select, .MuiInput-input': {
              ...theme.typography.body2,
              padding: theme.spacing(0.75, 1),
              borderRadius: theme.shape.borderRadius,
              backgroundColor: theme.palette.background.neutral,
            },
            '& .MuiSvgIcon-root': {
              right: 4,
            },
          },
        },
      },
    },
    MuiGridPanelFooter: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
          justifyContent: 'flex-end',
          '& .MuiButton-root': {
            '&:first-of-type': {
              marginRight: theme.spacing(1.5),
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            '&:last-of-type': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            },
          },
        },
      },
    },
  };
}
